import { POST_ACCOUNT_BANK_ACCOUNT_SUCCESS } from 'stash/actions/api/bankAccount';

import {
	LINK_BANK_COMPLETE,
	LINK_BANK_MICROS,
	LINK_BANK_MICROS_SUBMIT,
	LINK_BANK_REISSUE,
	LINK_BANK_REISSUE_COMPLETE,
	LINK_BANK_SELECT,
} from 'stash/actions/linkBank';

export default (dataLayer, getState, action) => {
	const state = getState();
	const isSignUp = window.location.pathname.match(/\/sign-up-*(\w*)\//);
	const isSignUpAddOn = window.location.pathname.match(/\/(\w*)-*sign-up\//);
	const signUpPath =
		(isSignUp && (isSignUp[1] || 'invest')) ||
		(isSignUpAddOn && isSignUpAddOn[1] && `${isSignUpAddOn[1]}AddOn`);
	const funnelType =
		signUpPath && signUpPath.charAt(0).toUpperCase() + signUpPath.slice(1);

	const uuid = state?.entities?.user?.uuid;

	if (action.type === POST_ACCOUNT_BANK_ACCOUNT_SUCCESS) {
		dataLayer.push({
			event: 'BankLinkComplete',
			uuid,
		});
	}

	if (action.type === LINK_BANK_SELECT) {
		dataLayer.push({
			event: 'BankLink',
			properties: {
				Action: 'SelectBank',
				Origin: window.location.pathname,
				Bank: action.bank.id,
				Brokerage: action.bank.brokeage_id, // [sic] typo on backend
				BankApproval: action.bank.api,
				Method: action.method,
				FunnelType: funnelType,
			},
		});
	}

	if (action.type === LINK_BANK_MICROS) {
		dataLayer.push({
			event: 'BankLink',
			properties: {
				Action: 'StartMicros',
				Origin: window.location.pathname,
				ScreenName: 'Pick Bank',
				FunnelType: funnelType,
			},
		});
	}

	if (action.type === LINK_BANK_MICROS_SUBMIT) {
		dataLayer.push({
			event: 'BankLink',
			properties: {
				Action: 'SubmitMicros',
				Origin: window.location.pathname,
				ScreenName: 'Pick Bank',
				FunnelType: funnelType,
			},
		});
	}

	if (action.type === LINK_BANK_COMPLETE) {
		dataLayer.push({
			event: 'BankLink',
			properties: {
				Action: 'ConfirmMicroDeposit',
				Origin: window.location.pathname,
				ScreenName: 'Confirm Micro Deposit',
				FunnelType: funnelType,
			},
		});
	}

	if (action.type === LINK_BANK_REISSUE) {
		dataLayer.push({
			event: 'BankLink',
			properties: {
				Origin: window.location.pathname,
				ScreenName: 'ResentMicroDepositScreen',
				FunnelType: funnelType,
			},
		});
	}

	if (action.type === LINK_BANK_REISSUE_COMPLETE) {
		dataLayer.push({
			event: 'BankLink',
			properties: {
				Action: 'ResendDeposits',
				Origin: window.location.pathname,
				ScreenName: 'ResentMicroDepositScreen',
				FunnelType: funnelType,
			},
		});
	}
};
