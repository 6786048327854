import { fetchRequestWithTypes } from './index.js';

// Creates a purchase-with-deposit transaction
// https://edge.stsh.io/documentation/1.0/transactions/buy_with_deposit.html
/* Examples
 *
 * Make deposit to stash cash
 * { "deposit": { "amount": 500 } }
 *
 * Buy investment with cash balance
 * { "buy": { "card_id": 13, "amount": 50 } }
 *
 * Buy investment with bank deposit.
 * { "deposit": { "amount": 50 }, "buy": { "card_id": 13, "amount": 50 } }
 *
 * Buy multiple investments at once with funding_source_id.
 * { "purchases": [ {"card_id": 5, "amount": 10.0} ], "funding_source_id": 1 }
 */

export const POST_BUY_WITH_DEPOSIT_REQUEST = 'POST_BUY_WITH_DEPOSIT_REQUEST';
export const POST_BUY_WITH_DEPOSIT_SUCCESS = 'POST_BUY_WITH_DEPOSIT_SUCCESS';
export const POST_BUY_WITH_DEPOSIT_ERRORED = 'POST_BUY_WITH_DEPOSIT_ERRORED';

export const postBuyWithDeposit = (
	accountId,
	card_id,
	amount,
	contribution_year,
	funding_source_id
) => {
	const body = {
		deposit: {
			amount,
			contribution_year,
		},
	};

	const buyingInvestment = card_id && card_id > -1;
	if (buyingInvestment) {
		body.buy = {
			card_id,
			amount,
		};
	}

	if (funding_source_id) body.funding_source_id = funding_source_id;

	return fetchRequestWithTypes({
		types: [
			POST_BUY_WITH_DEPOSIT_REQUEST,
			POST_BUY_WITH_DEPOSIT_SUCCESS,
			POST_BUY_WITH_DEPOSIT_ERRORED,
		],
		meta: {
			card_id,
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transactions/buy_with_deposit`,
			body,
		},
	});
};

export const POST_BUY_REQUEST = 'POST_BUY_REQUEST';
export const POST_BUY_SUCCESS = 'POST_BUY_SUCCESS';
export const POST_BUY_ERRORED = 'POST_BUY_ERRORED';

export const postBuy = (accountId, card_id, amount) => {
	const body = {
		transaction: {
			value: amount,
			card_id,
		},
	};

	return fetchRequestWithTypes({
		types: [POST_BUY_REQUEST, POST_BUY_SUCCESS, POST_BUY_ERRORED],
		meta: {
			card_id,
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transactions/buy`,
			body,
		},
	});
};

export const POST_SELL_REQUEST = 'POST_SELL_REQUEST';
export const POST_SELL_SUCCESS = 'POST_SELL_SUCCESS';
export const POST_SELL_ERRORED = 'POST_SELL_ERRORED';

export const postSell = (accountId, card_id, amount, sellAll) => {
	const transaction = {
		card_id,
	};

	// From docs: To sell all of an investment, set transaction[sell_all] to "1"
	// and omit transaction[value].
	// https://staging.stsh.io/documentation/1.0/transactions/sell.html
	if (sellAll) {
		transaction.sell_all = '1';
	} else {
		transaction.value = amount;
	}

	return fetchRequestWithTypes({
		types: [POST_SELL_REQUEST, POST_SELL_SUCCESS, POST_SELL_ERRORED],
		meta: {
			card_id,
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transactions/sell`,
			body: { transaction },
		},
	});
};

export const GET_BEGIN_TRANSACTION_REQUEST = 'GET_BEGIN_TRANSACTION_REQUEST';
export const GET_BEGIN_TRANSACTION_SUCCESS = 'GET_BEGIN_TRANSACTION_SUCCESS';
export const GET_BEGIN_TRANSACTION_ERRORED = 'GET_BEGIN_TRANSACTION_ERRORED';

export const getBeginTransaction = (accountId) =>
	fetchRequestWithTypes({
		types: [
			GET_BEGIN_TRANSACTION_REQUEST,
			GET_BEGIN_TRANSACTION_SUCCESS,
			GET_BEGIN_TRANSACTION_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transactions/begin`,
		},
	});
