/*
 * SIGN UP ACTIONS
 */
export const FLUSH_SIGN_UP = 'FLUSH_SIGN_UP';
export const flushSignUp = () => ({
	type: FLUSH_SIGN_UP,
});

export const SET_SIGN_UP_ACCOUNT_TYPE = 'SET_SIGN_UP_ACCOUNT_TYPE';
export const setSignUpAccountType = (data) => ({
	type: SET_SIGN_UP_ACCOUNT_TYPE,
	data,
});

export const SET_SIGN_UP_AUTO_STASH = 'SET_SIGN_UP_AUTO_STASH';
export const setSignUpAutostash = (data) => ({
	type: SET_SIGN_UP_AUTO_STASH,
	data,
});

export const SET_SIGNUP_ACCOUNTS_FOR_AUTOSTASH = 'SET_SIGNUP_ACCOUNTS_FOR_AUTOSTASH';
export const setSignUpAccountsForAutostash = (data) => ({
	type: SET_SIGNUP_ACCOUNTS_FOR_AUTOSTASH,
	data,
});

export const SET_SIGN_UP_DATA = 'SET_SIGN_UP_DATA';
export const setSignUpData = (data) => ({
	type: SET_SIGN_UP_DATA,
	data,
});

/*
 * SIGN UP ACTION FACTORY
 */
export const signUpAction = (actionType, name) => (value) => {
	return {
		type: actionType,
		name,
		value,
	};
};

export const SET_INVEST_ACCOUNT = 'SET_INVEST_ACCOUNT';
export const setInvestAccount = signUpAction(SET_INVEST_ACCOUNT, 'invest-account');

export const SET_RETIRE_ACCOUNT = 'SET_RETIRE_ACCOUNT';
export const setRetireAccount = signUpAction(SET_RETIRE_ACCOUNT, 'retire-account');

export const SET_SKIP_AUTOSTASH = 'SET_SKIP_AUTOSTASH';
export const setSkipAutostash = signUpAction(SET_SKIP_AUTOSTASH, 'skip-autostash');

export const SET_DEBIT_CONFIRM_ADDRESS = 'SET_DEBIT_CONFIRM_ADDRESS';
export const setDebitConfirmAddress = signUpAction(
	SET_DEBIT_CONFIRM_ADDRESS,
	'debit-confirm-address'
);

export const SET_DEBIT_CONFIRM_INFO = 'SET_DEBIT_CONFIRM_INFO';
export const setDebitConfirmInfo = signUpAction(
	SET_DEBIT_CONFIRM_INFO,
	'debit-confirm-info'
);

export const SET_DEBIT_START_FUND = 'SET_DEBIT_START_FUND';
export const setDebitStartFund = signUpAction(SET_DEBIT_START_FUND, 'debit-start-fund');

export const SET_INVEST_ADDON_VALUE_PROP = 'SET_INVEST_ADDON_VALUE_PROP';
export const setInvestAddonValueProp = signUpAction(
	SET_INVEST_ADDON_VALUE_PROP,
	'invest-addon'
);

export const SET_ADD_PAYMENT_COMPLETED = 'SET_ADD_PAYMENT_COMPLETED';
export const setAddPaymentCompleted = signUpAction(
	SET_ADD_PAYMENT_COMPLETED,
	'add-payment-completed'
);
