import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Flex,
	HouseQuestionIllustration,
	Layout,
	Link,
	MoneyQuestionIllustration,
	PrimaryButton,
	SmartLink,
	TertiaryButton,
	Text,
} from '@stashinvest/ui';
import { BanjoString } from '@stashinvest/react-banjo';
import {
	SignUpWrapper,
	SignUpWrapperInner,
} from 'stash/components/sign-up/SignUpScreenLayout';
import { CONTACT_URL } from 'stash/constants';

export const BankErrorScreen = (props) => {
	const {
		header,
		subheader,
		cta,
		ctaLink,
		ctaCallback,
		secondaryCta,
		secondaryCtaLink,
		secondaryCtaCallback,
		coreDisclosure,
		handleMount,
	} = props;
	const Graphic = props.graphic;

	useEffect(() => {
		handleMount();
		window.scrollTo(0, 0);
	}, [handleMount]);

	return (
		<Layout.PageWrap>
			<SignUpWrapper>
				<Graphic />
				<Text.H1 size="3" mb="xs" mt="s">
					{header}
				</Text.H1>
				<SignUpWrapperInner>
					<Text mb="m">{subheader}</Text>
					<Flex alignItems="center" flexDirection="column">
						<PrimaryButton to={ctaLink} onClick={ctaCallback} as={SmartLink} mb="xs">
							{cta}
						</PrimaryButton>
						{secondaryCta && (
							<TertiaryButton
								to={secondaryCtaLink}
								onClick={secondaryCtaCallback}
								as={SmartLink}
							>
								{secondaryCta}
							</TertiaryButton>
						)}
					</Flex>
					<Text.Caption align="center" mt="s">
						{coreDisclosure}
					</Text.Caption>
				</SignUpWrapperInner>
			</SignUpWrapper>
		</Layout.PageWrap>
	);
};

BankErrorScreen.defaultProps = {
	handleMount: () => {},
};

BankErrorScreen.propTypes = {
	graphic: PropTypes.string,
	header: PropTypes.string,
	subheader: PropTypes.string,
	cta: PropTypes.string,
	secondaryCta: PropTypes.string,
	ctaCallback: PropTypes.func,
	secondaryCtaCallback: PropTypes.func,
	ctaLink: PropTypes.string,
	secondaryCtaLink: PropTypes.string,
	coreDisclosure: PropTypes.any,
	handleMount: PropTypes.func.isRequired,
};
export const NoAccountScreen = ({ ctaCallback, onMount }) => {
	return (
		<BankErrorScreen
			graphic={MoneyQuestionIllustration}
			header={"You're almost there—are you sure you want to walk away?"}
			subheader={'We haven’t finished creating a Stash banking account for you yet.'}
			cta={'I want to apply'}
			secondaryCta={"I don't want to apply"}
			ctaCallback={ctaCallback}
			secondaryCtaLink="/"
			handleMount={onMount}
		/>
	);
};

export const SkipFundingScreen = ({ onMount, ctaCallback, secondaryCtaCallback }) => {
	return (
		<BankErrorScreen
			graphic={MoneyQuestionIllustration}
			header={"Are you sure? We'll hold off on mailing your card until you're ready."}
			subheader={
				"You won't be able to use your new account without transferring money to it."
			}
			cta={'Continue to funding'}
			secondaryCta={"I don't want to use my new account"}
			secondaryCtaLink={'/'}
			ctaCallback={ctaCallback}
			secondaryCtaCallback={secondaryCtaCallback}
			handleMount={onMount}
		/>
	);
};

export const AddressErrorScreen = ({ secondaryCtaCallback, ctaCallback, onMount }) => {
	return (
		<BankErrorScreen
			graphic={HouseQuestionIllustration}
			header={'We’re having issues opening an account using the address you gave us.'}
			subheader={`This is usually related to a character count limit or special characters.
			Please contact us to continue.`}
			cta={'Contact us'}
			ctaCallback={ctaCallback}
			secondaryCta={'Back to my information'}
			ctaLink={CONTACT_URL}
			secondaryCtaCallback={secondaryCtaCallback}
			handleMount={onMount}
		/>
	);
};

AddressErrorScreen.propTypes = {
	secondaryCtaCallback: PropTypes.func,
};

export const NameErrorScreen = ({ secondaryCtaCallback, ctaCallback, onMount }) => {
	return (
		<BankErrorScreen
			graphic={HouseQuestionIllustration}
			header={'We’re having some issues opening your account.'}
			subheader={'Please contact us to continue.'}
			cta={'Contact us'}
			ctaCallback={ctaCallback}
			secondaryCta={'Back to my information'}
			ctaLink={CONTACT_URL}
			secondaryCtaCallback={secondaryCtaCallback}
			handleMount={onMount}
		/>
	);
};

NameErrorScreen.propTypes = {
	secondaryCtaCallback: PropTypes.func,
};

export const RejectManualCure = (props) => (
	<BankErrorScreen
		graphic={HouseQuestionIllustration}
		header={'Sorry, there may be an issue with your account.'}
		subheader={'Please reach out to our customer service team for help.'}
		cta={'Contact Support'}
		ctaLink={CONTACT_URL}
		ctaCallback={props.handleCtaClick}
		handleMount={props.handleMount}
		coreDisclosure={
			<>
				<BanjoString stringId="information/disclose/genericBankMention" />{' '}
				<BanjoString stringId="information/disclose/bankAndInvest" />
			</>
		}
	/>
);

RejectManualCure.propTypes = {
	handleMount: PropTypes.func,
	handleCtaClick: PropTypes.func,
};

export const RejectNoCure = (props) => (
	<BankErrorScreen
		graphic={HouseQuestionIllustration}
		header={'Sorry, we’re unable to open a debit account for you.'}
		subheader={
			<Text>
				Please reach out to our customer service team for help through our{' '}
				<Link
					as={SmartLink}
					href={CONTACT_URL}
					onClick={props.handleContactClick}
					target="_blank"
				>
					contact form
				</Link>
				.
			</Text>
		}
		cta={'Got it'}
		ctaLink="/"
		coreDisclosure={
			<>
				<BanjoString stringId="information/disclose/genericBankMention" />{' '}
				<BanjoString stringId="information/disclose/bankAndInvest" />
			</>
		}
		ctaCallback={props.handleCtaClick}
		handleMount={props.handleMount}
	/>
);

RejectNoCure.propTypes = {
	handleMount: PropTypes.func,
	handleContactClick: PropTypes.func,
	handleCtaClick: PropTypes.func,
};

export const NoInvestAccount = (props) => (
	<BankErrorScreen
		graphic={HouseQuestionIllustration}
		header={'Finish setting up your investment account.'}
		subheader={
			<Text>
				You’ll need to finish setting up your personal investment account to use your
				debit account.
			</Text>
		}
		cta={'Continue'}
		coreDisclosure={
			<>
				<BanjoString stringId="information/disclose/genericBankMention" />{' '}
				<BanjoString stringId="information/disclose/bankAndInvest" />
			</>
		}
		ctaLink="/invest"
		ctaCallback={props.handleCtaClick}
		handleMount={props.handleMount}
	/>
);

NoInvestAccount.propTypes = {
	handleMount: PropTypes.func,
	handleCtaClick: PropTypes.func,
};

export const UnknownError = (props) => (
	<BankErrorScreen
		graphic={HouseQuestionIllustration}
		header={'Sorry, there may be an issue with your debit account.'}
		cta={'Contact us'}
		ctaLink={CONTACT_URL}
		subheader={
			<Text>
				Please reach out to our customer service team for help through our{' '}
				<Link
					as={SmartLink}
					href={CONTACT_URL}
					onClick={props.handleContactClick}
					target="_blank"
				>
					contact form
				</Link>
				.
			</Text>
		}
		ctaCallback={props.handleCtaClick}
		handleMount={props.handleMount}
		coreDisclosure={
			<>
				<BanjoString stringId="information/disclose/genericBankMention" />{' '}
				<BanjoString stringId="information/disclose/bankAndInvest" />
			</>
		}
	/>
);

UnknownError.propTypes = {
	handleMount: PropTypes.func,
	handleContactClick: PropTypes.func,
	handleCtaClick: PropTypes.func,
};
