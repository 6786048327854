export const DEBIT_SIGN_UP_CONFIRM_ADDRESS = 'DEBIT_SIGN_UP_CONFIRM_ADDRESS';
export const CONFIRM_ADDRESS_SCREEN_VIEW = 'CONFIRM_ADDRESS_SCREEN_VIEW';
export const CONFIRM_ADDRESS_CTA = 'CONFIRM_ADDRESS_CTA';
export const REVIEW_INFORMATION_SCREEN_VIEW = 'REVIEW_INFORMATION_SCREEN_VIEW';
export const REVIEW_INFORMATION_CTA = 'REVIEW_INFORMATION_CTA';
export const REVIEW_INFORMATION_EDIT = 'REVIEW_INFORMATION_EDIT';
export const REVIEW_INFORMATION_SETTINGS_LINK = 'REVIEW_INFORMATION_SETTINGS_LINK';
export const REVIEW_INFORMATION_CONTACT_LINK = 'REVIEW_INFORMATION_CONTACT_LINK';
export const ADDRESS_ERROR_SCREEN_VIEW = 'ADDRESS_ERROR_SCREEN_VIEW';
export const ADDRESS_ERROR_CTA = 'ADDRESS_ERROR_CTA';
export const ADDRESS_ERROR_SECONDARY_CTA = 'ADDRESS_ERROR_SECONDARY_CTA';
export const NAME_OR_ADDRESS_SCREEN_VIEW = 'NAME_OR_ADDRESS_SCREEN_VIEW';
export const NAME_OR_ADDRESS_ERROR_CTA = 'NAME_OR_ADDRESS_ERROR_CTA';
export const NAME_OR_ADDRESS_ERROR_SECONDARY_CTA = 'NAME_OR_ADDRESS_ERROR_SECONDARY_CTA';
export const CUSTOMER_AGREEMENT_SCREEN_VIEW = 'CUSTOMER_AGREEMENT_SCREEN_VIEW';
export const CUSTOMER_AGREEMENT_SECONDARY_CTA = 'CUSTOMER_AGREEMENT_SECONDARY_CTA';
export const CUSTOMER_AGREEMENT_CHECKBOX_CHANGE = 'CUSTOMER_AGREEMENT_CHECKBOX_CHANGE';
export const CUSTOMER_DEPOSIT_AGREEMENT_CHECKBOX_CHANGE =
	'CUSTOMER_DEPOSIT_AGREEMENT_CHECKBOX_CHANGE';
export const CUSTOMER_ESIGN_CHECKBOX_CHANGE = 'CUSTOMER_ESIGN_CHECKBOX_CHANGE';
export const CUSTOMER_STOCKBACK_TERMS_CHECKBOX_CHANGE =
	'CUSTOMER_STOCKBACK_TERMS_CHECKBOX_CHANGE';
export const NO_TO_DEBIT_ACCOUNT_SCREEN_VIEW = 'NO_TO_DEBIT_ACCOUNT_SCREEN_VIEW';
export const NO_TO_DEBIT_ACCOUNT_CTA = 'NO_TO_DEBIT_ACCOUNT_CTA';
export const NO_TO_DEBIT_ACCOUNT_SECONDARY_CTA = 'NO_TO_DEBIT_ACCOUNT_SECONDARY_CTA';
export const ACCOUNT_CREATED_FUND_SCREEN_VIEW = 'ACCOUNT_CREATED_FUND_SCREEN_VIEW';
export const ACCOUNT_CREATED_FUND_PREFILL_1 = 'ACCOUNT_CREATED_FUND_PREFILL_1';
export const ACCOUNT_CREATED_FUND_PREFILL_2 = 'ACCOUNT_CREATED_FUND_PREFILL_2';
export const ACCOUNT_CREATED_FUND_PREFILL_3 = 'ACCOUNT_CREATED_FUND_PREFILL_3';
export const ACCOUNT_CREATED_ENTERED_AMOUNT = 'ACCOUNT_CREATED_ENTERED_AMOUNT';
export const ACCOUNT_CREATED_SECONDARY_CTA = 'ACCOUNT_CREATED_SECONDARY_CTA';
export const NO_TO_DEBIT_FUNDING_SCREEN_VIEW = 'NO_TO_DEBIT_FUNDING_SCREEN_VIEW';
export const NO_TO_DEBIT_FUNDING_CTA = 'NO_TO_DEBIT_FUNDING_CTA';
export const NO_TO_DEBIT_FUNDING_SECONDARY_CTA = 'NO_TO_DEBIT_FUNDING_SECONDARY_CTA';

export const confirmAddress = (address) => ({
	type: DEBIT_SIGN_UP_CONFIRM_ADDRESS,
	address,
});

export const confirmAddressScreenView = () => ({
	type: CONFIRM_ADDRESS_SCREEN_VIEW,
});

export const confirmAddressCta = () => ({
	type: CONFIRM_ADDRESS_CTA,
});

export const reviewInformationScreenView = () => ({
	type: REVIEW_INFORMATION_SCREEN_VIEW,
});

export const reviewInformationScreenCta = () => ({
	type: REVIEW_INFORMATION_CTA,
});

export const reviewInformationScreenEdit = () => ({
	type: REVIEW_INFORMATION_EDIT,
});

export const reviewInformationScreenSettingsLink = () => ({
	type: REVIEW_INFORMATION_SETTINGS_LINK,
});

export const reviewInformationScreenContactLink = () => ({
	type: REVIEW_INFORMATION_CONTACT_LINK,
});

export const addressErrorScreenView = () => ({
	type: ADDRESS_ERROR_SCREEN_VIEW,
});

export const addressErrorCta = () => ({
	type: ADDRESS_ERROR_CTA,
});

export const addressErrorSecondaryCta = () => ({
	type: ADDRESS_ERROR_SECONDARY_CTA,
});

export const addressOrNameErrorScreenView = () => ({
	type: NAME_OR_ADDRESS_SCREEN_VIEW,
});

export const addressOrNameErrorCta = () => ({
	type: NAME_OR_ADDRESS_ERROR_CTA,
});

export const addressOrNameErrorSecondaryCta = () => ({
	type: NAME_OR_ADDRESS_ERROR_SECONDARY_CTA,
});

export const customerAgreementScreenView = () => ({
	type: CUSTOMER_AGREEMENT_SCREEN_VIEW,
});

export const customerAgreementSecondaryCta = () => ({
	type: CUSTOMER_AGREEMENT_SECONDARY_CTA,
});

export const customerAgreementCheckboxChange = () => ({
	type: CUSTOMER_AGREEMENT_CHECKBOX_CHANGE,
});

export const customerDepositAgreementCheckboxChange = () => ({
	type: CUSTOMER_DEPOSIT_AGREEMENT_CHECKBOX_CHANGE,
});

export const customerEsignCheckboxChange = () => ({
	type: CUSTOMER_ESIGN_CHECKBOX_CHANGE,
});

export const customerStockbackTermsCheckboxChange = () => ({
	type: CUSTOMER_STOCKBACK_TERMS_CHECKBOX_CHANGE,
});

export const noToDebitScreenScreenView = () => ({
	type: NO_TO_DEBIT_ACCOUNT_SCREEN_VIEW,
});

export const noToDebitScreenCta = () => ({
	type: NO_TO_DEBIT_ACCOUNT_CTA,
});

export const noToDebitScreenSecondaryCta = () => ({
	type: NO_TO_DEBIT_ACCOUNT_SECONDARY_CTA,
});

export const accountCreatedFundScreenView = () => ({
	type: ACCOUNT_CREATED_FUND_SCREEN_VIEW,
});

export const accountCreatedFundPrefill1 = () => ({
	type: ACCOUNT_CREATED_FUND_PREFILL_1,
});

export const accountCreatedFundPrefill2 = () => ({
	type: ACCOUNT_CREATED_FUND_PREFILL_2,
});

export const accountCreatedFundPrefill3 = () => ({
	type: ACCOUNT_CREATED_FUND_PREFILL_3,
});

export const accountCreatedFundEnteredAmount = () => ({
	type: ACCOUNT_CREATED_ENTERED_AMOUNT,
});

export const accountCreatedFundSecondaryCta = () => ({
	type: ACCOUNT_CREATED_SECONDARY_CTA,
});

export const noToDebitFundingScreenView = () => ({
	type: NO_TO_DEBIT_FUNDING_SCREEN_VIEW,
});

export const noToDebitFundingCta = () => ({
	type: NO_TO_DEBIT_FUNDING_CTA,
});

export const noToDebitFundingSecondaryCta = () => ({
	type: NO_TO_DEBIT_FUNDING_SECONDARY_CTA,
});

export const DEBIT_VALUE_PROP_SCREEN_VIEW = 'DEBIT_VALUE_PROP_SCREEN_VIEW';
export const valuePropScreenView = () => ({
	type: DEBIT_VALUE_PROP_SCREEN_VIEW,
});

export const DEBIT_VALUE_PROP_CTA = 'DEBIT_VALUE_PROP_CTA';
export const valuePropCtaClick = () => ({
	type: DEBIT_VALUE_PROP_CTA,
});

export const DEBIT_SIGN_UP_NO_CURE_SCREEN_VIEW = 'DEBIT_SIGN_UP_NO_CURE_SCREEN_VIEW';
export const noCureScreenView = () => ({
	type: DEBIT_SIGN_UP_NO_CURE_SCREEN_VIEW,
});

export const DEBIT_SIGN_UP_NO_CURE_CONTACT_CLICK = 'DEBIT_SIGN_UP_NO_CURE_CONTACT_CLICK';
export const noCureContactClick = () => ({
	type: DEBIT_SIGN_UP_NO_CURE_CONTACT_CLICK,
});

export const DEBIT_SIGN_UP_NO_CURE_PRIMARY_CTA_CLICK =
	'DEBIT_SIGN_UP_NO_CURE_PRIMARY_CTA_CLICK';
export const noCurePrimaryCtaClick = () => ({
	type: DEBIT_SIGN_UP_NO_CURE_PRIMARY_CTA_CLICK,
});

export const DEBIT_SIGN_UP_UNKNOWN_SCREEN_VIEW = 'DEBIT_SIGN_UP_UNKNOWN_SCREEN_VIEW';
export const unknownScreenView = () => ({
	type: DEBIT_SIGN_UP_UNKNOWN_SCREEN_VIEW,
});

export const DEBIT_SIGN_UP_UNKNOWN_CONTACT_CLICK = 'DEBIT_SIGN_UP_UNKNOWN_CONTACT_CLICK';
export const unknownContactClick = () => ({
	type: DEBIT_SIGN_UP_UNKNOWN_CONTACT_CLICK,
});

export const DEBIT_SIGN_UP_UNKNOWN_PRIMARY_CTA_CLICK =
	'DEBIT_SIGN_UP_UNKNOWN_PRIMARY_CTA_CLICK';
export const unknownPrimaryCtaClick = () => ({
	type: DEBIT_SIGN_UP_UNKNOWN_PRIMARY_CTA_CLICK,
});

export const DEBIT_SIGN_UP_MANUAL_CURE_SCREEN_VIEW =
	'DEBIT_SIGN_UP_MANUAL_CURE_SCREEN_VIEW';
export const manualCureScreenView = () => ({
	type: DEBIT_SIGN_UP_MANUAL_CURE_SCREEN_VIEW,
});

export const DEBIT_SIGN_UP_MANUAL_CURE_PRIMARY_CTA_CLICK =
	'DEBIT_SIGN_UP_MANUAL_CURE_PRIMARY_CTA_CLICK';
export const manualCurePrimaryCtaClick = () => ({
	type: DEBIT_SIGN_UP_MANUAL_CURE_PRIMARY_CTA_CLICK,
});

export const DEBIT_SIGN_UP_ACCOUNT_SCREEN_VIEW = 'DEBIT_SIGN_UP_ACCOUNT_SCREEN_VIEW';
export const debitSignUpAccountScreenView = () => ({
	type: DEBIT_SIGN_UP_ACCOUNT_SCREEN_VIEW,
});

export const DEBIT_SIGN_UP_ACCOUNT_PLAY_LINKS = 'DEBIT_SIGN_UP_ACCOUNT_PLAY_LINKS';
export const debitSignUpDownloadAppClick = (platform) => ({
	type: DEBIT_SIGN_UP_ACCOUNT_PLAY_LINKS,
	platform,
});

export const DEBIT_SIGN_UP_ACCOUNT_CTA = 'DEBIT_SIGN_UP_ACCOUNT_CTA';
export const debitSignUpCta = () => ({
	type: DEBIT_SIGN_UP_ACCOUNT_CTA,
});

export const DEBIT_SIGN_UP_SECONDARY_CTA = 'DEBIT_SIGN_UP_SECONDARY_CTA';
export const debitSignUpSecondaryCta = () => ({
	type: DEBIT_SIGN_UP_SECONDARY_CTA,
});

export const DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_SCREEN_VIEW =
	'DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_SCREEN_VIEW';
export const noInvestAccountScreenView = () => ({
	type: DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_SCREEN_VIEW,
});

export const DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_CTA_CLICK =
	'DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_CTA_CLICK';
export const noInvestAccountCtaClick = () => ({
	type: DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_CTA_CLICK,
});

export const DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_SCREEN_VIEW =
	'DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_SCREEN_VIEW';
export const debitSignUpTransferFunds = () => ({
	type: DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_SCREEN_VIEW,
});

export const DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_CTA =
	'DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_CTA';
export const debitSignUpTransferFundsCta = () => ({
	type: DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_CTA,
});

export const DEBIT_SIGN_UP_TRANSFER_FUNDS_ENTER_AMOUNT =
	'DEBIT_SIGN_UP_TRANSFER_FUNDS_ENTER_AMOUNT';
export const debitSignUpAmountBlur = () => ({
	type: DEBIT_SIGN_UP_TRANSFER_FUNDS_ENTER_AMOUNT,
});
