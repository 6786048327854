import { fetchRequestWithTypes } from './index.js';

export const GET_ADVISORY_AGREEMENT_REQUEST = 'GET_ADVISORY_AGREEMENT_REQUEST';
export const GET_ADVISORY_AGREEMENT_SUCCESS = 'GET_ADVISORY_AGREEMENT_SUCCESS';
export const GET_ADVISORY_AGREEMENT_ERRORED = 'GET_ADVISORY_AGREEMENT_ERRORED';

export const getAdvisoryAgreement = () => {
	return fetchRequestWithTypes({
		types: [
			GET_ADVISORY_AGREEMENT_REQUEST,
			GET_ADVISORY_AGREEMENT_SUCCESS,
			GET_ADVISORY_AGREEMENT_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/agreements/advisory`,
		},
	});
};

export const GET_ADVISORY_SIGNATURE_REQUEST = 'GET_ADVISORY_SIGNATURE_REQUEST';
export const GET_ADVISORY_SIGNATURE_SUCCESS = 'GET_ADVISORY_SIGNATURE_SUCCESS';
export const GET_ADVISORY_SIGNATURE_ERRORED = 'GET_ADVISORY_SIGNATURE_ERRORED';

export const getAdvisorySignature = () => {
	return fetchRequestWithTypes({
		types: [
			GET_ADVISORY_SIGNATURE_REQUEST,
			GET_ADVISORY_SIGNATURE_SUCCESS,
			GET_ADVISORY_SIGNATURE_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/userEsignatures/latest`,
		},
	});
};

export const POST_ADVISORY_SIGNATURE_REACTIVATION_REQUEST =
	'POST_ADVISORY_SIGNATURE_REACTIVATION_REQUEST';
export const POST_ADVISORY_SIGNATURE_REACTIVATION_SUCCESS =
	'POST_ADVISORY_SIGNATURE_REACTIVATION_SUCCESS';
export const POST_ADVISORY_SIGNATURE_REACTIVATION_ERRORED =
	'POST_ADVISORY_SIGNATURE_REACTIVATION_ERRORED';

export const postAdvisorySignatureReactivation = () => {
	return fetchRequestWithTypes({
		types: [
			POST_ADVISORY_SIGNATURE_REACTIVATION_REQUEST,
			POST_ADVISORY_SIGNATURE_REACTIVATION_SUCCESS,
			POST_ADVISORY_SIGNATURE_REACTIVATION_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/concierge/api/v1/users/:uuid/userEsignatures/reactivate`,
		},
	});
};
