import {
	GET_ADVISORY_AGREEMENT_SUCCESS,
	GET_ADVISORY_SIGNATURE_SUCCESS,
	POST_ADVISORY_SIGNATURE_REACTIVATION_SUCCESS,
} from 'stash/actions/api';

export const initialState = {
	agreements: [],
	signature: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ADVISORY_AGREEMENT_SUCCESS: {
			return {
				...state,
				agreements: action.response.agreements,
				coreDisclosureStatement: action.response.coreDisclosureStatement,
				disclosureStatement: action.response.disclosureStatement,
				header: action.response.header,
				subheader: action.response.subheader,
			};
		}
		case GET_ADVISORY_SIGNATURE_SUCCESS:
		case POST_ADVISORY_SIGNATURE_REACTIVATION_SUCCESS: {
			return {
				...state,
				signature: action.response.userEsignature,
			};
		}
		default:
			return state;
	}
};
