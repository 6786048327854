import { fetchRequestWithTypes } from './index.js';
import { CLIENT_IDS, REDIRECT_URLS, BASE_URLS } from 'stash/constants/authParams';
import { getCookie } from 'stash/utils/cookies';

// DELETEs refresh token when the user logs out.

export const DELETE_REFRESH_TOKEN_REQUEST = 'DELETE_REFRESH_TOKEN_REQUEST';
export const DELETE_REFRESH_TOKEN_SUCCESS = 'DELETE_REFRESH_TOKEN_SUCCESS';
export const DELETE_REFRESH_TOKEN_ERRORED = 'DELETE_REFRESH_TOKEN_ERRORED';

export const deleteRefreshToken = (refreshToken) => {
	const STASH_ENV = window && window.Stash.env;
	const clientId = CLIENT_IDS[STASH_ENV];
	const redirectUrl = REDIRECT_URLS[STASH_ENV];
	const basePath = BASE_URLS[STASH_ENV];
	return fetchRequestWithTypes({
		types: [
			DELETE_REFRESH_TOKEN_REQUEST,
			DELETE_REFRESH_TOKEN_SUCCESS,
			DELETE_REFRESH_TOKEN_ERRORED,
		],
		request: {
			method: 'POST',
			basePath,
			path: `/api/v1/revoke?token_type_hint=refresh_token&client_id=${clientId}&redirect_uri=${redirectUrl}`,
			headers: {
				Accept: 'application/json',
			},
			body: {
				token: refreshToken,
			},
		},
	});
};

export const POST_THIRD_PARTY_AUTH_REQUEST = 'POST_THIRD_PARTY_AUTH_REQUEST';
export const POST_THIRD_PARTY_AUTH_SUCCESS = 'POST_THIRD_PARTY_AUTH_SUCCESS';
export const POST_THIRD_PARTY_AUTH_ERRORED = 'POST_THIRD_PARTY_AUTH_ERRORED';

export const postThirdPartyAuth = (body) => {
	const STASH_ENV = window && window.Stash.env;
	const basePath = BASE_URLS[STASH_ENV];
	return fetchRequestWithTypes({
		types: [
			POST_THIRD_PARTY_AUTH_REQUEST,
			POST_THIRD_PARTY_AUTH_SUCCESS,
			POST_THIRD_PARTY_AUTH_ERRORED,
		],
		request: {
			method: 'POST',
			basePath,
			path: '/api/v1/auth_third_party',
			body,
		},
	});
};

export const POST_CHECK_FORGOT_PASSWORD_VALIDITY_REQUEST =
	'POST_CHECK_FORGOT_PASSWORD_VALIDITY_REQUEST';
export const POST_CHECK_FORGOT_PASSWORD_VALIDITY_SUCCESS =
	'POST_CHECK_FORGOT_PASSWORD_VALIDITY_SUCCESS';
export const POST_CHECK_FORGOT_PASSWORD_VALIDITY_ERRORED =
	'POST_CHECK_FORGOT_PASSWORD_VALIDITY_ERRORED';

export const checkForgotPasswordValidity = (token) =>
	fetchRequestWithTypes({
		types: [
			POST_CHECK_FORGOT_PASSWORD_VALIDITY_REQUEST,
			POST_CHECK_FORGOT_PASSWORD_VALIDITY_SUCCESS,
			POST_FORGOT_PASSWORD_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/password_reset_token',
			body: {
				user: {
					token,
				},
			},
		},
	});

// POSTs a forgot password

export const POST_FORGOT_PASSWORD_REQUEST = 'POST_FORGOT_PASSWORD_REQUEST';
export const POST_FORGOT_PASSWORD_SUCCESS = 'POST_FORGOT_PASSWORD_SUCCESS';
export const POST_FORGOT_PASSWORD_ERRORED = 'POST_FORGOT_PASSWORD_ERRORED';

export const postForgotPassword = (body) =>
	fetchRequestWithTypes({
		types: [
			POST_FORGOT_PASSWORD_REQUEST,
			POST_FORGOT_PASSWORD_SUCCESS,
			POST_FORGOT_PASSWORD_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/api/v1/auth/forgot_password',
			body,
		},
	});

export const POST_TOKEN_REQUEST = 'POST_TOKEN_REQUEST';
export const POST_TOKEN_SUCCESS = 'POST_TOKEN_SUCCESS';
export const POST_TOKEN_ERRORED = 'POST_TOKEN_ERRORED';

export const postTokenRequest = (code, verifier) => {
	const STASH_ENV =
		window.Stash.env === '__STASH_ENV__' && getCookie('stocktonDev')
			? 'stockton_dev'
			: window.Stash.env;
	const clientId = CLIENT_IDS[STASH_ENV];
	const redirectUrl = REDIRECT_URLS[STASH_ENV];
	const basePath = BASE_URLS[STASH_ENV];
	return fetchRequestWithTypes({
		types: [POST_TOKEN_REQUEST, POST_TOKEN_SUCCESS, POST_TOKEN_ERRORED],
		request: {
			method: 'POST',
			basePath,
			path: `/api/v1/token?grant_type=authorization_code&client_id=${clientId}&redirect_uri=${redirectUrl}`,
			headers: {
				Accept: 'application/json',
			},
			body: {
				code_verifier: verifier,
				code,
			},
		},
	});
};

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERRORED = 'REFRESH_TOKEN_ERRORED';

export const refreshTokenRequest = (token) => {
	const STASH_ENV =
		window.Stash.env === '__STASH_ENV__' && getCookie('stocktonDev')
			? 'stockton_dev'
			: window.Stash.env;
	const clientId = CLIENT_IDS[STASH_ENV];
	const redirectUrl = REDIRECT_URLS[STASH_ENV];
	const basePath = BASE_URLS[STASH_ENV];
	return fetchRequestWithTypes({
		types: [REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_ERRORED],
		request: {
			method: 'POST',
			basePath,
			path: `/api/v1/token?grant_type=refresh_token&client_id=${clientId}&redirect_uri=${redirectUrl}`,
			headers: {
				Accept: 'application/json',
			},
			body: {
				refresh_token: token,
			},
		},
	});
};
