export const BANKING_FEE_OUTLINE_URL =
	'https://cdn.stash.com/messaging-assets/banking/Stash-Banking-Fee-Outline-V2.pdf';

export const STRIDE_STOCK_BACK_REWARDS_DISCLOSURES =
	'https://lp.stash.com/stride-stash-stock-back-rewards-terms-and-conditions/';

export const STRIDE_DEPOSIT_ACCOUNT_AGREEMENT =
	'https://cdn.stash.com/disclosures/stride-deposit-account-agreement.pdf';

export const STRIDE_BANK_PRIVACY_POLICY =
	'https://cdn.stash.com/disclosures/stride-privacy-policy.pdf';

export const STASH_ESIGN_AGREEMENT =
	'https://cdn.stash.com/disclosures/stash-e-sign-agreement.pdf';
