import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout, Loader } from '@stashinvest/ui';
import { connect } from 'react-redux';
import { NoInvestAccount } from 'stash/components/sign-up/bank/BankErrorScreen';
import { getRegistrationEligibility } from '../actions';
import ValuePropScreen from 'stash/components/sign-up/bank/ValuePropScreen';
import {
	noInvestAccountCtaClick,
	noInvestAccountScreenView,
	valuePropCtaClick,
} from 'stash/actions/debitSignUpTrackingEvents';

const mapStateToProps = (state) => ({
	eligibility: state?.entities?.bank?.eligibility ?? {},
});

const mapDispatchToProps = {
	getRegistrationEligibility,
	valuePropCtaClick,
	noInvestAccountScreenView,
	noInvestAccountCtaClick,
};

export const EligibilityRoute = (props) => {
	const {
		eligibility,
		getRegistrationEligibility,
		noInvestAccountScreenView,
		noInvestAccountCtaClick,
	} = props;

	useEffect(() => {
		if (!eligibility.status) {
			getRegistrationEligibility();
		}
	}, [getRegistrationEligibility, eligibility.status]);

	if (!eligibility.status) return <Loader center />;

	if (eligibility.status === 'notEligible') {
		return (
			<Layout.PageWrap>
				<NoInvestAccount
					handleMount={noInvestAccountScreenView}
					handleCtaClick={noInvestAccountCtaClick}
				/>
			</Layout.PageWrap>
		);
	}

	if (eligibility.status === 'eligible') {
		return <ValuePropScreen />;
	}

	return null;
};

EligibilityRoute.propTypes = {
	eligibility: PropTypes.object,
	getRegistrationEligibility: PropTypes.func,
	noInvestAccountScreenView: PropTypes.func,
	noInvestAccountCtaClick: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityRoute);
