import { PATCH_RETIRE_APPLICATION_SUCCESS } from 'stash/actions/api/investorApplication';

export const objectSet = (array, value = true) => {
	return array.reduce((acc, item) => {
		return {
			...acc,
			[item]: value,
		};
	}, {});
};

export default (state = {}, action) => {
	switch (action.type) {
		case PATCH_RETIRE_APPLICATION_SUCCESS:
			return objectSet(action.response.eligible_account_types);

		default:
			return state;
	}
};
