/**
 * This middleware fires events to the Google Tag Manager dataLayer.
 *
 * We use GTM to connect to any services which are outside the scope
 * of this web application. This includes analytics, marketing tags,
 * pixels, postbacks, webhooks, etc.
 *
 * Web - Stash Mixpanel Events Spec
 * https://docs.google.com/spreadsheets/d/1c2QAiEbGDLgXXMa92CDL0XOydSPpB2Y6SRr-rC4k1vA/edit#gid=0
 *
 */
import { isProdOrStaging, SHA1 } from 'stash/utils';
import { getCookie } from 'stash/utils/cookies';

import { LOCATION_CHANGE } from 'connected-react-router';

import { LOG_IN, LOG_OUT } from 'stash/actions/session';
import {
	GET_LAUNCH_INFO_SUCCESS,
	GET_USER_SUCCESS,
	POST_USER_SUCCESS,
} from 'stash/actions/api';
import { PATCH_RETIRE_APPLICATION_SUCCESS } from 'stash/actions/api/investorApplication';
import { PATCH_PROFILE_SUCCESS } from 'stash/actions/api/profile';
import {
	PATCH_REACTIVATE_ACCOUNT_REQUEST,
	PATCH_REACTIVATE_ACCOUNT_SUCCESS,
	POST_ACCOUNT_CLOSE_REQUEST,
	POST_ACCOUNT_CLOSE_SUCCESS,
	POST_ACCOUNT_SUBMIT_SUCCESS,
	POST_ACCOUNT_SUCCESS,
} from 'stash/actions/api/account';
import {
	SHOW_STASHER_FEEDBACK,
	STASHER_CLOSE_FEEDBACK,
	STASHER_SEND_FEEDBACK,
} from 'stash/actions/feedback';
import {
	DELETE_ACCOUNT_AUTO_STASH_SUCCESS,
	POST_ACCOUNT_AUTO_STASH_SUCCESS,
	POST_AUTO_STASH_ALLOCATION_SUCCESS,
} from 'stash/actions/api/autoStash';
import { TRACK_SECURITIES_LENDING } from 'stash/actions/api/securitiesLending';
import { TRACK_EVENT, TRACK_INTERACTION, trackEvent } from 'stash/actions/tracking';
import { POST_REFERRAL_DATA_SUCCESS } from 'stash/actions/api/referrals';
import { PROMO_COOKIE_NAME } from 'stash/middleware/promo';

import bankLinkHandler from './events/bankLink';
import cardDetailsHandler from './events/cardDetails';
import transactionHandler from './events/transaction';
import bookmarkHandler from './events/bookmarks';
import debitRegistrationHandler from './events/debitRegistration';

// -----------------------------------------------------------------------------
// Helper functions
// -----------------------------------------------------------------------------

export const dataLayer = {
	push: (payload) => {
		if (window.dataLayer) {
			window.dataLayer.push(payload);
			!isProdOrStaging() &&
				getCookie('stashEventLogger') &&
				console.info(`[${payload.event}]`, payload);
		} else {
			console.log('GTM dataLayer not found');
		}
	},
};

const pushAccountEvent = (event, account, funnelType) => {
	dataLayer.push({
		event,
		properties: {
			AccountId: account.id,
			AccountType: account.type,
			FunnelType: funnelType,
		},
	});
};

const pushPhoneNumberEvent = (event, { email, uuid, phoneNumber }) => {
	dataLayer.push({
		event,
		properties: {
			email,
			uuid,
			phoneNumber,
		},
	});
};

// -----------------------------------------------------------------------------
// Event handlers
// -----------------------------------------------------------------------------
const handlers = [
	bankLinkHandler,
	transactionHandler,
	cardDetailsHandler,
	bookmarkHandler,
	debitRegistrationHandler,
];

const eventsMiddleware =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		// ---------------------------------------------------------------------------
		// Set up state and meta data that is reused
		// ---------------------------------------------------------------------------
		const state = getState();
		const accountId = action.meta && action.meta.accountId;
		const accountType = accountId && state.entities.account[accountId].type;

		// Need to send FunnelType with all events sent during sign up or add on sign up
		const isSignUp = window.location.pathname.match(/\/sign-up-*(\w*)\//);
		const isSignUpAddOn = window.location.pathname.match(/\/(\w*)-*sign-up\//);
		const isSignUpRetireAddOn = /retire-add-on/.test(window.location.pathname);

		const signUpPath =
			(isSignUp && (isSignUp[1] || 'invest')) ||
			(isSignUpAddOn && isSignUpAddOn[1] && `${isSignUpAddOn[1]}AddOn`);
		const funnelType =
			signUpPath && signUpPath.charAt(0).toUpperCase() + signUpPath.slice(1);

		const stashPromoCode = getCookie(PROMO_COOKIE_NAME);

		// ---------------------------------------------------------------------------
		// Event handlers
		// ---------------------------------------------------------------------------
		// Event handlers in the array above are called with the signature:
		// (dataLayer, getState, action) => {}
		handlers.forEach((handler) => handler(dataLayer, getState, action));

		// ---------------------------------------------------------------------------
		// Session events
		// ---------------------------------------------------------------------------
		switch (action.type) {
			case LOG_IN:
				dataLayer.push({
					event: 'Sign In',
					uuid: action.uuid,
				});
				break;

			case LOG_OUT:
				dataLayer.push({
					event: 'Sign Out',
				});
				break;

			default:
				break;
		}

		switch (action.type) {
			case GET_LAUNCH_INFO_SUCCESS:
			case GET_USER_SUCCESS:
			case POST_USER_SUCCESS: {
				const phone_number = action.response.profile.phone_number;

				dataLayer.push({
					event: 'User Ready',
					email: action.response.user.email,
					uuid: action.response.user.uuid,
					emailSHA1: SHA1(action.response.user.email),
					web_reg_version: action.response.user.web_reg_version,
				});

				// This payload MUST match the payload of UpdatePhoneNumber.
				if (phone_number) {
					pushPhoneNumberEvent('User Ready with Phone Number', {
						email: action.response.user.email,
						uuid: action.response.user.uuid,
						phoneNumber: phone_number,
					});
				}
				break;
			}

			default:
				break;
		}

		// ---------------------------------------------------------------------------
		// Interaction and custom events
		// ---------------------------------------------------------------------------
		if (action.type === TRACK_INTERACTION) {
			dataLayer.push({
				event: 'Interaction',
				properties: {
					...action.properties,
					FunnelType: funnelType,
				},
			});
		}

		if (action.type === TRACK_EVENT) {
			dataLayer.push({
				event: action.eventName,
				properties: {
					...action.data,
					FunnelType: funnelType,
				},
			});
		}

		// ---------------------------------------------------------------------------
		// Reg flow events
		// ---------------------------------------------------------------------------
		switch (action.type) {
			case PATCH_RETIRE_APPLICATION_SUCCESS: {
				const application = action.request.body.investor_application;

				if (isSignUpRetireAddOn) {
					if (application.filing_status) {
						dispatch(
							trackEvent('RetireAddOn', {
								ScreenName: 'TaxFilingStatus',
								FilingStatus: application.filing_status,
							})
						);
					}
					if (application.household_income_bracket) {
						dispatch(
							trackEvent('RetireAddOn', {
								ScreenName: 'HouseholdIncome',
								HouseholdIncome: application.household_income_bracket,
							})
						);
					}
				}

				break;
			}

			case PATCH_PROFILE_SUCCESS: {
				if (!(action.request.body && action.request.body.profile)) {
					break;
				} else if (action.request.body.profile.citizenship_country) {
					dataLayer.push({
						event: 'VerifyAccount',
						properties: {
							ScreenName: 'Citizenship',
							FunnelType: funnelType,
						},
					});
				} else if (action.request.body.profile.phone_number) {
					const phone_number = action.response.user_profile.phone_number;

					pushPhoneNumberEvent('UpdatePhoneNumber', {
						email: state.entities.user.email,
						uuid: state.entities.user.uuid,
						phoneNumber: phone_number,
					});

					dataLayer.push({
						event: 'VerifyAccount',
						properties: {
							ScreenName: 'PersonalIdentification',
							FunnelType: funnelType,
						},
					});
				} else if (action.request.body.profile.social_security_number) {
					dataLayer.push({
						event: 'VerifyAccount',
						properties: {
							ScreenName: 'SSN',
							FunnelType: funnelType,
						},
					});
					dataLayer.push({
						event: 'SubmitSSN',
						properties: {
							ScreenName: 'SSN',
							FunnelType: funnelType,
						},
					});
				}

				break;
			}

			default:
				break;
		}

		// ---------------------------------------------------------------------------
		// Auto-Stash events
		// ---------------------------------------------------------------------------
		switch (action.type) {
			case POST_ACCOUNT_AUTO_STASH_SUCCESS: {
				const auto_stash = action.request.body.auto_stash;
				const allocation = auto_stash.allocations[0] || {
					amount: 0,
					card_id: -1,
				};

				dataLayer.push({
					event: 'AutoStash',
					properties: {
						Action: 'Create',
						AccountType: accountType,
						FunnelType: funnelType,
						Amount: allocation.amount,
						Schedule: auto_stash.frequency,
						Origin: window.location.pathname,
						Card: allocation.card_id,
					},
				});
				break;
			}

			case POST_AUTO_STASH_ALLOCATION_SUCCESS: {
				const amount = action.request.body.amount;
				const allocation = action.response.auto_stash_allocation;
				const payment_method = action.meta.source === -1 ? 'StashCash' : 'Bank';

				// We fire two events because 'Transaction' -> 'Auto-Stash' is unintuitive.
				// TODO: Remove one of them.
				const eventTransaction = {
					event: 'Transaction',
					properties: {
						Type: 'Auto-Stash',
						AccountType: accountType,
						FunnelType: funnelType,
						Amount: amount,
						Card: allocation.card_id,
						Origin: window.location.pathname,
						PaymentMethod: payment_method,
					},
				};
				dataLayer.push(eventTransaction);

				// TODO: We might need to populate Schedule, Start, Stop.
				const eventAutoStash = {
					event: 'AutoStash',
					properties: {
						Action: 'Update',
						AccountType: accountType,
						FunnelType: funnelType,
						Amount: amount,
						Schedule: '',
						Start: '',
						Stop: '',
						Origin: window.location.pathname,
						Card: allocation.card_id,
					},
				};

				dataLayer.push(eventAutoStash);
				break;
			}

			case DELETE_ACCOUNT_AUTO_STASH_SUCCESS: {
				dataLayer.push({
					event: 'AutoStash',
					properties: {
						Action: 'Delete',
						AccountType: accountType,
					},
				});
				break;
			}

			default:
				break;
		}

		// ---------------------------------------------------------------------------
		// Stash feedback events
		// ---------------------------------------------------------------------------
		switch (action.type) {
			// event: send user input from feedback form after user has turned off A-S
			case STASHER_SEND_FEEDBACK: {
				dataLayer.push({
					event: 'StasherFeedback',
					properties: {
						Origin: action.data.origin,
						Feedback: action.data.feedback,
						Action: 'SendFeedback',
					},
				});
				break;
			}

			//event: send user has seen the feedback form and turned off A-S
			case SHOW_STASHER_FEEDBACK: {
				dataLayer.push({
					event: 'StasherFeedback',
					properties: {
						Origin: action.data.origin,
					},
				});
				break;
			}

			//event: send user has seen feedback form after turning off A-S and closes out
			case STASHER_CLOSE_FEEDBACK: {
				dataLayer.push({
					event: 'StasherFeedback',
					properties: {
						Origin: action.data.origin,
						Action: 'CloseFeedback',
					},
				});
				break;
			}

			default:
				break;
		}

		// ---------------------------------------------------------------------------
		// Securities lending
		// ---------------------------------------------------------------------------
		if (action.type === TRACK_SECURITIES_LENDING) {
			dataLayer.push({
				event: 'SecuritiesLending',
				properties: action.properties,
			});
		}

		// ---------------------------------------------------------------------------
		// Account events
		// ---------------------------------------------------------------------------
		switch (action.type) {
			case POST_ACCOUNT_SUCCESS:
				if (isSignUpRetireAddOn) {
					dispatch(
						trackEvent('RetireAddOn', {
							ScreenName: 'ESig',
							Action: 'ESigAgree',
						})
					);
				} else {
					pushAccountEvent('AccountCreate', action.response.account, funnelType);
				}
				break;

			case POST_ACCOUNT_SUBMIT_SUCCESS:
				pushAccountEvent('AccountESigSubmit', action.response.account, funnelType);
				break;

			default:
				break;
		}

		// ---------------------------------------------------------------------------
		// Experiment events
		// --------------------------------------------------------------------------
		// initialize gtm after the token exchange, to avoid leaking the auth code
		if (
			action.type === LOCATION_CHANGE &&
			action.payload.location.pathname !== '/authorize'
		) {
			dataLayer.push({
				event: 'Impression',
				properties: {
					ScreenName: action.payload.location.pathname,
					Version: window.Stash.version,
					StashPromoCode: stashPromoCode,
				},
			});
		}

		// ---------------------------------------------------------------------------
		// General account events
		// ---------------------------------------------------------------------------

		if (action.type === 'APP_SEND_SMS') {
			dataLayer.push({
				event: 'AppSendSMS',
				properties: {
					ScreenName: action.screenName,
					FunnelType: funnelType,
				},
			});
		}

		// ---------------------------------------------------------------------------
		// Close accounts
		// ---------------------------------------------------------------------------
		switch (action.type) {
			case POST_ACCOUNT_CLOSE_REQUEST:
				dataLayer.push({
					event: 'CloseAccount',
					properties: {
						Action: 'CloseRequest',
					},
				});
				break;
			case POST_ACCOUNT_CLOSE_SUCCESS:
				dataLayer.push({
					event: 'CloseAccount',
					properties: {
						Action: 'CloseSuccess',
					},
				});
				break;
			default:
				break;
		}

		// ---------------------------------------------------------------------------
		// Inactive accounts
		// ---------------------------------------------------------------------------
		if (
			action.type === LOCATION_CHANGE &&
			action.payload.location.pathname === '/reactivate-account'
		) {
			dataLayer.push({
				event: 'ReactivateAccount',
				properties: {
					Action: 'TermsAndCondition',
				},
			});
		}

		switch (action.type) {
			case PATCH_REACTIVATE_ACCOUNT_REQUEST:
				dataLayer.push({
					event: 'ReactivateAccount',
					properties: {
						Action: 'SubmitAgreement',
					},
				});
				break;
			case PATCH_REACTIVATE_ACCOUNT_SUCCESS:
				dataLayer.push({
					event: 'ReactivateAccount',
					properties: {
						Action: 'SubmitSuccess',
					},
				});
				break;
			default:
				break;
		}

		if (action.type === '@@router/LOCATION_CHANGE') {
			if (action.payload.location.pathname === '/referrals/new') {
				dataLayer.push({
					event: 'SendReferral',
					properties: {
						ScreenName: 'Referral',
					},
				});
			}
		}

		if (action.type === POST_REFERRAL_DATA_SUCCESS) {
			dataLayer.push({
				event: 'SendReferral',
				properties: {
					ScreenName: 'Referral',
					ReferralAction: 'Invite',
				},
			});
		}

		return next(action);
	};

export default eventsMiddleware;
