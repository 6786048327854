import {
	ACCOUNT_CREATED_ENTERED_AMOUNT,
	ACCOUNT_CREATED_FUND_PREFILL_1,
	ACCOUNT_CREATED_FUND_PREFILL_2,
	ACCOUNT_CREATED_FUND_PREFILL_3,
	ACCOUNT_CREATED_FUND_SCREEN_VIEW,
	ACCOUNT_CREATED_SECONDARY_CTA,
	ADDRESS_ERROR_CTA,
	ADDRESS_ERROR_SCREEN_VIEW,
	ADDRESS_ERROR_SECONDARY_CTA,
	CONFIRM_ADDRESS_CTA,
	CONFIRM_ADDRESS_SCREEN_VIEW,
	CUSTOMER_AGREEMENT_CHECKBOX_CHANGE,
	CUSTOMER_AGREEMENT_SCREEN_VIEW,
	CUSTOMER_AGREEMENT_SECONDARY_CTA,
	DEBIT_SIGN_UP_ACCOUNT_CTA,
	DEBIT_SIGN_UP_ACCOUNT_PLAY_LINKS,
	DEBIT_SIGN_UP_ACCOUNT_SCREEN_VIEW,
	DEBIT_SIGN_UP_MANUAL_CURE_PRIMARY_CTA_CLICK,
	DEBIT_SIGN_UP_MANUAL_CURE_SCREEN_VIEW,
	DEBIT_SIGN_UP_NO_CURE_CONTACT_CLICK,
	DEBIT_SIGN_UP_NO_CURE_PRIMARY_CTA_CLICK,
	DEBIT_SIGN_UP_NO_CURE_SCREEN_VIEW,
	DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_CTA_CLICK,
	DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_SCREEN_VIEW,
	DEBIT_SIGN_UP_SECONDARY_CTA,
	DEBIT_SIGN_UP_TRANSFER_FUNDS_ENTER_AMOUNT,
	DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_CTA,
	DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_SCREEN_VIEW,
	DEBIT_SIGN_UP_UNKNOWN_CONTACT_CLICK,
	DEBIT_SIGN_UP_UNKNOWN_PRIMARY_CTA_CLICK,
	DEBIT_SIGN_UP_UNKNOWN_SCREEN_VIEW,
	DEBIT_VALUE_PROP_CTA,
	DEBIT_VALUE_PROP_SCREEN_VIEW,
	NAME_OR_ADDRESS_ERROR_CTA,
	NAME_OR_ADDRESS_ERROR_SECONDARY_CTA,
	NAME_OR_ADDRESS_SCREEN_VIEW,
	NO_TO_DEBIT_ACCOUNT_CTA,
	NO_TO_DEBIT_ACCOUNT_SCREEN_VIEW,
	NO_TO_DEBIT_ACCOUNT_SECONDARY_CTA,
	NO_TO_DEBIT_FUNDING_CTA,
	NO_TO_DEBIT_FUNDING_SCREEN_VIEW,
	NO_TO_DEBIT_FUNDING_SECONDARY_CTA,
	REVIEW_INFORMATION_CONTACT_LINK,
	REVIEW_INFORMATION_CTA,
	REVIEW_INFORMATION_EDIT,
	REVIEW_INFORMATION_SCREEN_VIEW,
	REVIEW_INFORMATION_SETTINGS_LINK,
} from 'stash/actions/debitSignUpTrackingEvents';

import { INIT_TRANSFER_REQUEST, POST_DEBIT_ACCOUNT_REQUEST } from 'stash/actions';

export default (dataLayer, getState, action) => {
	const pushEvent = (properties) => {
		dataLayer.push({
			event: 'CheckingReg',
			properties,
		});
	};

	const events = {
		[DEBIT_VALUE_PROP_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'WebValueProp',
				Action: '',
			});
		},
		[DEBIT_VALUE_PROP_CTA]: () => {
			pushEvent({
				ScreenName: 'WebValueProp',
				Action: 'Continue',
			});
		},
		[CONFIRM_ADDRESS_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'ConfirmAddress',
			});
		},
		[CONFIRM_ADDRESS_CTA]: () => {
			pushEvent({
				ScreenName: 'ConfirmAddress',
				Action: 'ConfirmAddressCTA',
			});
		},
		[REVIEW_INFORMATION_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'ReviewYourInfo',
				Action: '',
			});
		},
		[REVIEW_INFORMATION_CTA]: () => {
			pushEvent({
				ScreenName: 'ReviewYourInfo',
				Action: 'ReviewYourInfoCTA',
			});
		},
		[REVIEW_INFORMATION_EDIT]: () => {
			pushEvent({
				ScreenName: 'ReviewYourInfo',
				Action: 'EditAddress',
			});
		},
		[REVIEW_INFORMATION_SETTINGS_LINK]: () => {
			pushEvent({
				ScreenName: 'ReviewYourInfo',
				Action: 'Settings',
			});
		},
		[REVIEW_INFORMATION_CONTACT_LINK]: () => {
			pushEvent({
				ScreenName: 'ReviewYourInfo',
				Action: 'Contact',
			});
		},
		[DEBIT_SIGN_UP_NO_CURE_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'RejectedAccount',
				Action: '',
			});
		},
		[DEBIT_SIGN_UP_NO_CURE_CONTACT_CLICK]: () => {
			pushEvent({
				ScreenName: 'RejectedAccount',
				Action: 'ContactCX',
			});
		},
		[DEBIT_SIGN_UP_NO_CURE_PRIMARY_CTA_CLICK]: () => {
			pushEvent({
				ScreenName: 'RejectedAccount',
				Action: 'Continue',
			});
		},
		[DEBIT_SIGN_UP_MANUAL_CURE_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'ManualRejection',
				Action: '',
			});
		},
		[DEBIT_SIGN_UP_MANUAL_CURE_PRIMARY_CTA_CLICK]: () => {
			pushEvent({
				ScreenName: 'ManualRejection',
				Action: 'ManualRejectionCTA',
			});
		},
		[ADDRESS_ERROR_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'AddressError',
				Action: '',
			});
		},
		[ADDRESS_ERROR_CTA]: () => {
			pushEvent({
				ScreenName: 'AddressError',
				Action: 'InvalidAddressCTA',
			});
		},
		[ADDRESS_ERROR_SECONDARY_CTA]: () => {
			pushEvent({
				ScreenName: 'AddressError',
				Action: 'InvalidAddressCTA2',
			});
		},
		[NAME_OR_ADDRESS_ERROR_CTA]: () => {
			pushEvent({
				ScreenName: 'AddressNameError',
				Action: '',
			});
		},
		[NAME_OR_ADDRESS_ERROR_SECONDARY_CTA]: () => {
			pushEvent({
				ScreenName: 'AddressNameError',
				Action: 'InvalidNameAddressCTA',
			});
		},
		[NAME_OR_ADDRESS_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'AddressNameError',
				Action: 'InvalidNameAddressCTA2',
			});
		},
		[CUSTOMER_AGREEMENT_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'CustomerAgreement',
				Action: '',
			});
		},
		[POST_DEBIT_ACCOUNT_REQUEST]: () => {
			pushEvent({
				ScreenName: 'CustomerAgreement',
				Action: 'CustomerAgreementCTA',
			});
		},
		[CUSTOMER_AGREEMENT_SECONDARY_CTA]: () => {
			pushEvent({
				ScreenName: 'CustomerAgreement',
				Action: 'CustomerAgreementDecline',
			});
		},
		[CUSTOMER_AGREEMENT_CHECKBOX_CHANGE]: () => {
			pushEvent({
				ScreenName: 'CustomerAgreement',
				Action: 'Checkbox',
			});
		},
		[NO_TO_DEBIT_ACCOUNT_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'NotoAccount',
				Action: '',
			});
		},
		[NO_TO_DEBIT_ACCOUNT_CTA]: () => {
			pushEvent({
				ScreenName: 'NotoAccount',
				Action: 'NotoAccountCTA',
			});
		},
		[NO_TO_DEBIT_ACCOUNT_SECONDARY_CTA]: () => {
			pushEvent({
				ScreenName: 'NotoAccount',
				Action: 'NotoAccountCTA2',
			});
		},
		[ACCOUNT_CREATED_FUND_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: '',
			});
		},
		[ACCOUNT_CREATED_FUND_PREFILL_1]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: 'Prefill1',
			});
		},
		[ACCOUNT_CREATED_FUND_PREFILL_2]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: 'Prefill2',
			});
		},
		[ACCOUNT_CREATED_FUND_PREFILL_3]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: 'Prefill3',
			});
		},
		[ACCOUNT_CREATED_ENTERED_AMOUNT]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: 'EnterAmount',
			});
		},
		[INIT_TRANSFER_REQUEST]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: 'Continue',
			});
		},
		[ACCOUNT_CREATED_SECONDARY_CTA]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: 'SkipFund',
			});
		},
		[NO_TO_DEBIT_FUNDING_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'SkipFunding',
				Action: '',
			});
		},
		[NO_TO_DEBIT_FUNDING_CTA]: () => {
			pushEvent({
				ScreenName: 'SkipFunding',
				Action: 'Continue',
			});
		},
		[NO_TO_DEBIT_FUNDING_SECONDARY_CTA]: () => {
			pushEvent({
				ScreenName: 'SkipFunding',
				Action: 'SkipFunding',
			});
		},
		[DEBIT_SIGN_UP_ACCOUNT_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'AccountSuccess',
				Action: '',
			});
		},
		[DEBIT_SIGN_UP_ACCOUNT_PLAY_LINKS]: (platform) => {
			pushEvent({
				ScreenName: 'AccountSuccess',
				Action: 'DownloadApp',
				Platform:
					platform === 'android' ? 'PlayStore' : platform === 'ios' ? 'AppleStore' : null,
			});
		},
		[DEBIT_SIGN_UP_ACCOUNT_CTA]: () => {
			pushEvent({
				ScreenName: 'AccountSuccess',
				Action: 'TextDownload',
			});
		},
		[DEBIT_SIGN_UP_SECONDARY_CTA]: () => {
			pushEvent({
				ScreenName: 'AccountSuccess',
				Action: 'CheckingRegCompleteCTA',
			});
		},
		[DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'NoInvestAccount',
				Action: '',
			});
		},
		[DEBIT_SIGN_UP_NO_INVEST_ACCOUNT_CTA_CLICK]: () => {
			pushEvent({
				ScreenName: 'NoInvestAccount',
				Action: 'Continue',
			});
		},
		[DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFundError',
			});
		},
		[DEBIT_SIGN_UP_TRANSFER_FUNDS_ERROR_CTA]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFundError',
				Action: 'BackToHome',
			});
		},
		[DEBIT_SIGN_UP_TRANSFER_FUNDS_ENTER_AMOUNT]: () => {
			pushEvent({
				ScreenName: 'AccountCreatedFund',
				Action: 'EnterAmount',
			});
		},
		[DEBIT_SIGN_UP_UNKNOWN_SCREEN_VIEW]: () => {
			pushEvent({
				ScreenName: 'UnknownAccount',
				Action: '',
			});
		},
		[DEBIT_SIGN_UP_UNKNOWN_CONTACT_CLICK]: () => {
			pushEvent({
				ScreenName: 'UnknownAccount',
				Action: 'ContactCX',
			});
		},
		[DEBIT_SIGN_UP_UNKNOWN_PRIMARY_CTA_CLICK]: () => {
			pushEvent({
				ScreenName: 'UnknownAccount',
				Action: 'Continue',
			});
		},
	};

	// match existing events
	if (typeof events[action.type] === 'function') {
		events[action.type](action);
	}
};
