import { useCachedGet } from '@stashinvest/use-fetch';

export const CAPABILITIES = {
	NOT_APPLICABLE: 'CAPABILITY_NOT_APPLICABLE',
	CANNOT_PAY_FOR_SUBSCRIPTION: 'CAPABILITY_CANNOT_PAY_FOR_SUBSCRIPTION',
	CAN_PAY_FOR_SUBSCRIPTION_ONLY_FROM_INTERNAL_FUNDING:
		'CAPABILITY_CAN_PAY_FOR_SUBSCRIPTION_ONLY_FROM_INTERNAL_FUNDING',
	CAN_PAY_FOR_SUBSCRIPTION_FROM_EXTERNAL_FUNDING:
		'CAPABILITY_CAN_PAY_FOR_SUBSCRIPTION_FROM_EXTERNAL_FUNDING',
	PAID_FOR_SUBSCRIPTION: 'CAPABILITY_PAID_FOR_SUBSCRIPTION',
};

export function isPaywalled(data) {
	if (!data) return; // ensure loading state is still undefined

	//px-capabilities currently only returns "CANNOT_PAY_FOR_SUBSCRIPTION"
	return (
		data?.capabilities?.capabilityToPayForSubscription ===
		CAPABILITIES.CANNOT_PAY_FOR_SUBSCRIPTION
	);
}

export function useIsPaywalled() {
	const { data: getCapabilities } = useCachedGet({
		path: `/apis/customers/v1/users/:uuid/capabilities`,
		onMount: true,
	});

	return isPaywalled(getCapabilities);
}
