/**
 * This middleware fires events to Braze
 */

import {
	AUTH_SESSION_START,
	GET_LAUNCH_INFO_SUCCESS,
	GET_USER_SUCCESS,
	LOG_OUT,
	POST_USER_SUCCESS,
} from 'stash/actions';

import { tryWarn } from 'stash/utils';
import {
	automaticallyShowInAppMessages,
	changeUser,
	destroy,
	initialize,
	openSession,
	removeAllSubscriptions,
} from '@braze/web-sdk';
import { BRAZE_ENV_APP_KEY, BRAZE_SDK_ENDPOINT } from 'stash/constants';

// No easy way to track this in the Braze SDK so doing it here.
let sessionIsOpen = false;

const initializeBraze = tryWarn(() => {
	if (process.env.NODE_ENV === 'test') return;

	initialize(BRAZE_ENV_APP_KEY, {
		baseUrl: BRAZE_SDK_ENDPOINT,
		enableLogging: /-/.test(window.location.hostname),
		allowUserSuppliedJavascript: true,
	});
});

const destroyBrazeInstance = tryWarn(() => {
	sessionIsOpen = false;
	removeAllSubscriptions();
	destroy();
});

const setBrazeUser = tryWarn((...args) => {
	if (sessionIsOpen) return;
	sessionIsOpen = true;
	automaticallyShowInAppMessages();
	changeUser(...args);
	openSession();
});

const brazeReducer =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		switch (action.type) {
			case AUTH_SESSION_START:
				initializeBraze();
				break;
			case LOG_OUT:
				destroyBrazeInstance();
				break;
			case GET_USER_SUCCESS:
			case POST_USER_SUCCESS:
			case GET_LAUNCH_INFO_SUCCESS:
				setBrazeUser(action.response.user.braze_id);
				break;
			default:
				break;
		}

		return next(action);
	};

export default brazeReducer;
