import { fetchRequestWithTypes } from './index.js';

export const GET_REFERRAL_MANAGEMENT_REQUEST = 'GET_REFERRAL_MANAGEMENT_REQUEST';
export const GET_REFERRAL_MANAGEMENT_SUCCESS = 'GET_REFERRAL_MANAGEMENT_SUCCESS';
export const GET_REFERRAL_MANAGEMENT_ERRORED = 'GET_REFERRAL_MANAGEMENT_ERRORED';

export const getReferralManagement = () =>
	fetchRequestWithTypes({
		types: [
			GET_REFERRAL_MANAGEMENT_REQUEST,
			GET_REFERRAL_MANAGEMENT_SUCCESS,
			GET_REFERRAL_MANAGEMENT_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/referrals/api/v1/users/:uuid/views/management',
		},
	});

export const GET_REFERRAL_HOME_REQUEST = 'GET_REFERRAL_HOME_REQUEST';
export const GET_REFERRAL_HOME_SUCCESS = 'GET_REFERRAL_HOME_SUCCESS';
export const GET_REFERRAL_HOME_ERRORED = 'GET_REFERRAL_HOME_ERRORED';

export const getReferralHome = () =>
	fetchRequestWithTypes({
		types: [
			GET_REFERRAL_HOME_REQUEST,
			GET_REFERRAL_HOME_SUCCESS,
			GET_REFERRAL_HOME_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/referrals/api/v2/users/:uuid/views/home`,
		},
	});

export const GET_REFERRAL_REGISTRATION_REQUEST = 'GET_REFERRAL_REGISTRATION_REQUEST';
export const GET_REFERRAL_REGISTRATION_SUCCESS = 'GET_REFERRAL_REGISTRATION_SUCCESS';
export const GET_REFERRAL_REGISTRATION_ERRORED = 'GET_REFERRAL_REGISTRATION_ERRORED';

export const getReferralRegistration = () =>
	fetchRequestWithTypes({
		types: [
			GET_REFERRAL_REGISTRATION_REQUEST,
			GET_REFERRAL_REGISTRATION_SUCCESS,
			GET_REFERRAL_REGISTRATION_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/referrals/api/v1/users/:uuid/views/registration',
		},
	});

export const POST_REFERRAL_DATA_REQUEST = 'POST_REFERRAL_DATA_REQUEST';
export const POST_REFERRAL_DATA_SUCCESS = 'POST_REFERRAL_DATA_SUCCESS';
export const POST_REFERRAL_DATA_ERRORED = 'POST_REFERRAL_DATA_ERRORED';

export const postReferralData = (email, code) =>
	fetchRequestWithTypes({
		types: [
			POST_REFERRAL_DATA_REQUEST,
			POST_REFERRAL_DATA_SUCCESS,
			POST_REFERRAL_DATA_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/api/v1/referrals/proto_user',
			body: {
				email,
				code,
			},
		},
	});

export const PUT_REFERRAL_SOLICITOR_REQUEST = 'PUT_REFERRAL_SOLICITOR_REQUEST';
export const PUT_REFERRAL_SOLICITOR_SUCCESS = 'PUT_REFERRAL_SOLICITOR_SUCCESS';
export const PUT_REFERRAL_SOLICITOR_ERRORED = 'PUT_REFERRAL_SOLICITOR_ERRORED';

export const putReferralAgreement = (firstName) =>
	fetchRequestWithTypes({
		types: [
			PUT_REFERRAL_SOLICITOR_REQUEST,
			PUT_REFERRAL_SOLICITOR_SUCCESS,
			PUT_REFERRAL_SOLICITOR_ERRORED,
		],
		request: {
			method: 'PUT',
			path: '/apis/referrals/api/v1/users/:uuid/referralAgreement',
			body: {
				first_name: firstName,
			},
		},
	});
