/*
 * REACTIVATION ACTION FACTORY
 */
export const reactivationAction = (actionType, name) => (value) => {
	return {
		type: actionType,
		name,
		value,
	};
};

export const SET_PROFILE = 'SET_PROFILE';
export const setProfile = reactivationAction(SET_PROFILE, 'confirm_profile');

export const SET_IDV = 'SET_IDV';
export const setIdv = reactivationAction(SET_IDV, 'idv_flow');

export const SET_SILENT_IDV = 'SET_SILENT_IDV';
export const setSilentIdv = reactivationAction(SET_SILENT_IDV, 'silent_idv');

export const SET_INVESTOR_PROFILE = 'SET_INVESTOR_PROFILE';
export const setInvestorProfile = reactivationAction(
	SET_INVESTOR_PROFILE,
	'confirm_investor_profile'
);

export const SET_TIER_SELECTION = 'SET_TIER_SELECTION';
export const setTierSelection = reactivationAction(SET_TIER_SELECTION, 'tier_selection');

export const SET_BILLING_SUMMARY = 'SET_BILLING_SUMMARY';
export const setBillingSummary = reactivationAction(
	SET_BILLING_SUMMARY,
	'billing_summary'
);
