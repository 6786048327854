import { POST_AUTO_STASH_ALLOCATION_SUCCESS } from '../../actions';

export default (state = {}, action) => {
	switch (action.type) {
		case POST_AUTO_STASH_ALLOCATION_SUCCESS:
			return {
				...state,
				postAutoStash: action.response,
			};
		default:
			return state;
	}
};
