import { fetchRequestWithTypes } from './index.js'; // GETs a user's Auto Stash settings for a given account.

// GETs a user's Auto Stash settings for a given account.

export const GET_ACCOUNT_AUTO_STASH_REQUEST = 'GET_ACCOUNT_AUTO_STASH_REQUEST';
export const GET_ACCOUNT_AUTO_STASH_SUCCESS = 'GET_ACCOUNT_AUTO_STASH_SUCCESS';
export const GET_ACCOUNT_AUTO_STASH_ERRORED = 'GET_ACCOUNT_AUTO_STASH_ERRORED';

export const getAccountAutoStash = (accountId, accountType) =>
	fetchRequestWithTypes({
		types: [
			GET_ACCOUNT_AUTO_STASH_REQUEST,
			GET_ACCOUNT_AUTO_STASH_SUCCESS,
			GET_ACCOUNT_AUTO_STASH_ERRORED,
		],
		meta: {
			accountId,
			accountType,
		},
		request: {
			method: 'GET',
			path: `/apis/autostash/api/v1/users/:uuid/accounts/${accountId}/auto_stash`,
		},
	});

// POST a user's Auto Stash settings
// POST /apis/api/v1/users/:uuid/accounts/:account_id/auto_stash

export const POST_ACCOUNT_AUTO_STASH_REQUEST = 'POST_ACCOUNT_AUTO_STASH_REQUEST';
export const POST_ACCOUNT_AUTO_STASH_SUCCESS = 'POST_ACCOUNT_AUTO_STASH_SUCCESS';
export const POST_ACCOUNT_AUTO_STASH_ERRORED = 'POST_ACCOUNT_AUTO_STASH_ERRORED';

export const postAccountAutoStash = (accountId, body) =>
	fetchRequestWithTypes({
		types: [
			POST_ACCOUNT_AUTO_STASH_REQUEST,
			POST_ACCOUNT_AUTO_STASH_SUCCESS,
			POST_ACCOUNT_AUTO_STASH_ERRORED,
		],
		meta: {
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/autostash/api/v1/users/:uuid/accounts/${accountId}/auto_stash`,
			body,
		},
	});

export const PAUSE_SET_SCHEDULE_REQUEST = 'PAUSE_SET_SCHEDULE_REQUEST';
export const PAUSE_SET_SCHEDULE_SUCCESS = 'PAUSE_SET_SCHEDULE_SUCCESS';
export const PAUSE_SET_SCHEDULE_ERRORED = 'PAUSE_SET_SCHEDULE_ERRORED';

export const pauseSetSchedule = (accountId, enabled, next_execution) =>
	fetchRequestWithTypes({
		types: [
			PAUSE_SET_SCHEDULE_REQUEST,
			PAUSE_SET_SCHEDULE_SUCCESS,
			PAUSE_SET_SCHEDULE_ERRORED,
		],
		meta: {
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/autostash/api/v1/users/:uuid/accounts/${accountId}/auto_stash`,
			body: {
				auto_stash: {
					enabled,
					next_execution,
				},
			},
		},
	});

// POST an Auto Stash allocation for a single card.

export const POST_AUTO_STASH_ALLOCATION_REQUEST = 'POST_AUTO_STASH_ALLOCATION_REQUEST';
export const POST_AUTO_STASH_ALLOCATION_SUCCESS = 'POST_AUTO_STASH_ALLOCATION_SUCCESS';
export const POST_AUTO_STASH_ALLOCATION_ERRORED = 'POST_AUTO_STASH_ALLOCATION_ERRORED';

export const postAutoStashAllocation = (accountId, card_id, amount, source) => {
	return fetchRequestWithTypes({
		types: [
			POST_AUTO_STASH_ALLOCATION_REQUEST,
			POST_AUTO_STASH_ALLOCATION_SUCCESS,
			POST_AUTO_STASH_ALLOCATION_ERRORED,
		],
		meta: {
			accountId,
			source,
		},
		request: {
			method: 'POST',
			path: `/apis/autostash/api/v1/users/:uuid/accounts/${accountId}/auto_stash/${card_id}`,
			body: {
				amount,
			},
		},
	});
};

// DELETE an Auto Stash allocation for a single card.
export const DELETE_AUTO_STASH_ALLOCATION_REQUEST =
	'DELETE_AUTO_STASH_ALLOCATION_REQUEST';
export const DELETE_AUTO_STASH_ALLOCATION_SUCCESS =
	'DELETE_AUTO_STASH_ALLOCATION_SUCCESS';
export const DELETE_AUTO_STASH_ALLOCATION_ERRORED =
	'DELETE_AUTO_STASH_ALLOCATION_ERRORED';

export const deleteAutoStashAllocation = (accountId, cardId) => {
	return fetchRequestWithTypes({
		types: [
			DELETE_AUTO_STASH_ALLOCATION_REQUEST,
			DELETE_AUTO_STASH_ALLOCATION_SUCCESS,
			DELETE_AUTO_STASH_ALLOCATION_ERRORED,
		],
		meta: {
			accountId,
			cardId,
		},
		request: {
			method: 'DELETE',
			path: `/apis/autostash/api/v1/users/:uuid/accounts/${accountId}/auto_stash/${cardId}`,
		},
	});
};

// DELETE a user's Auto Stash settings

export const DELETE_ACCOUNT_AUTO_STASH_REQUEST = 'DELETE_ACCOUNT_AUTO_STASH_REQUEST';
export const DELETE_ACCOUNT_AUTO_STASH_SUCCESS = 'DELETE_ACCOUNT_AUTO_STASH_SUCCESS';
export const DELETE_ACCOUNT_AUTO_STASH_ERRORED = 'DELETE_ACCOUNT_AUTO_STASH_ERRORED';

export const deleteAccountAutoStash = (accountId) =>
	fetchRequestWithTypes({
		types: [
			DELETE_ACCOUNT_AUTO_STASH_REQUEST,
			DELETE_ACCOUNT_AUTO_STASH_SUCCESS,
			DELETE_ACCOUNT_AUTO_STASH_ERRORED,
		],
		meta: {
			accountId,
		},
		request: {
			method: 'DELETE',
			path: `/apis/autostash/api/v1/users/:uuid/accounts/${accountId}/auto_stash`,
		},
	});

// GET transaction history (transfers) for a strategy

export const GET_STRATEGY_TRANSFERS_REQUEST = 'GET_STRATEGY_TRANSFERS_REQUEST';
export const GET_STRATEGY_TRANSFERS_SUCCESS = 'GET_STRATEGY_TRANSFERS_SUCCESS';
export const GET_STRATEGY_TRANSFERS_ERRORED = 'GET_STATEGY_TRANSFERS_ERRORED';

export const getStrategyTransfers = (strategyName) => {
	return fetchRequestWithTypes({
		types: [
			GET_STRATEGY_TRANSFERS_REQUEST,
			GET_STRATEGY_TRANSFERS_SUCCESS,
			GET_STRATEGY_TRANSFERS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/autostash/api/v1/users/:uuid/strategies/${strategyName}/transfers`,
		},
		strategyName: strategyName,
	});
};
