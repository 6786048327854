export const TRANSFER_FREQUENCY = {
	oneTime: {
		frequency: 'ONCE',
		display: 'once',
		quantified: 1,
	},
	daily: {
		frequency: 'DAILY',
		display: 'daily',
		quantified: 260,
	},
	weekly: {
		frequency: 'WEEKLY',
		display: 'weekly',
		quantified: 52,
	},
	biWeekly: {
		frequency: 'BI-WEEKLY',
		display: 'biweekly',
		quantified: 26,
	},
	monthly: {
		frequency: 'MONTHLY',
		display: 'monthly',
		quantified: 12,
	},
};

// TODO: DEPRECATE and clean up autostash mappings.
export const AUTO_STASH_FREQUENCY_TEXT = {
	DAILY: 'every weekday',
	WEEKLY: 'every week',
	'BI-WEEKLY': 'every two weeks',
	MONTHLY: 'every month',
};

export const PITHY_AUTO_STASH_FREQUENCY_TEXT = {
	ONCE: '',
	DAILY: 'daily',
	WEEKLY: 'weekly',
	'BI-WEEKLY': 'biweekly',
	MONTHLY: 'monthly',
};

// USE THIS MAPPING INSTEAD OF 'AUTO_STASH_FREQUENCY_TEXT'.
export const AUTO_STASH_FREQUENCY_MAP = {
	DAILY: {
		lowercase: 'every weekday',
		titlecase: 'Every weekday',
		shortTitle: 'Daily',
	},
	WEEKLY: {
		lowercase: 'every week',
		titlecase: 'Every week',
		shortTitle: 'Weekly',
	},
	'BI-WEEKLY': {
		lowercase: 'every two weeks',
		titlecase: 'Every two weeks',
		shortTitle: 'Biweekly',
	},
	MONTHLY: {
		lowercase: 'every month',
		titlecase: 'Every month',
		shortTitle: 'Monthly',
	},
	NONE: {
		lowercase: 'just once',
		titlecase: 'Just once',
		shortTitle: 'Once',
	},
};

export const AUTO_STASH_FREQUENCY_OPTIONS = [
	{
		value: 'DAILY',
		title: 'Every weekday',
		short_title: 'every weekday',
	},
	{
		value: 'WEEKLY',
		title: 'Every week',
		short_title: 'every week',
	},
	{
		value: 'BI-WEEKLY',
		title: 'Every two weeks',
		short_title: 'every two weeks',
	},
	{
		value: 'MONTHLY',
		title: 'Every month',
		short_title: 'every month',
	},
];

export const PITHY_AUTO_STASH_FREQUENCY_OPTIONS = [
	{
		value: 'DAILY',
		title: 'Daily',
		short_title: 'every weekday',
	},
	{
		value: 'WEEKLY',
		title: 'Weekly',
		short_title: 'every week',
	},
	{
		value: 'BI-WEEKLY',
		title: 'Biweekly',
		short_title: 'every two weeks',
	},
	{
		value: 'MONTHLY',
		title: 'Monthly',
		short_title: 'every month',
	},
];

// 'NONE' is not an actual value for the backend.
export const ALL_AUTO_STASH_FREQUENCY_OPTIONS = [
	...AUTO_STASH_FREQUENCY_OPTIONS,
	{
		value: 'NONE',
		title: 'Just once',
		short_title: 'just once',
	},
];

export const RISK_LEVEL_NAME = {
	1: 'Conservative',
	2: 'Moderate',
	3: 'Aggressive',
	4: 'Secret',
};

export const RISK_LEVELS = {
	conservative: 1,
	moderate: 2,
	aggressive: 3,
	secret: 4,
};

export const ACCOUNT_STATES = {
	complete: 'complete',
	queued_for_apex: 'queued_for_apex',
	pending: 'pending',
	back_office: 'back_office',
	in_closing_process: 'in_closing_process',
	in_activation_process: 'in_activation_process',
	in_reopen_process: 'in_reopen_process',
	closed: 'closed',
	indeterminate: 'indeterminate',
	rejected: 'rejected',
	impeded: 'impeded',
	incomplete: 'incomplete',
	inactive: 'inactive',
	errored: 'errored',
	unsupported: 'unsupported',
};

export const VALID_AASM_STATES = {
	complete: true,
	queued_for_apex: true,
	pending: true,
	back_office: true,
	in_closing_process: true,
	in_activation_process: true,
};

export const CLOSED_AASM_STATES = {
	closed: true,
};

export const REJECTED_AASM_STATES = {
	rejected: true,
	impeded: true,
	indeterminate: true,
	closed: true,
	errored: true,
};

export const ARCHIVE_RESTRICTED_REASONS = {
	ACCOUNT_VERIFICATION_CALL_IN: true,
	CLOSED: true,
	DEFAULT: true,
	IN_CLOSING_PROCESS: true,
	IN_CLOSING_PROCESS_OUTDATED_USER_AGENT: true,
	OFAC: true,
	OFAC_COUNTRY: true,
};

export const IDV_FLOW_PARENT = {
	REACTIVATION: 'reactivation',
};
