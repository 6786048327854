import { useCallback, useEffect } from 'react';
import useAnalytics from './useAnalytics';
import { datadogRum } from '@datadog/browser-rum';
import { isMobileWeb, isProdOrStaging } from 'stash/utils';
import { getCookie } from 'stash/utils/cookies';
import { getAnalyticsInstance } from '../initializers/analytics';
import { getStoredAttributions } from 'stash/utils/instrumentation/storedAttributions';

const ATTRIBUTION_EVENTS = {
	BUY_SETUP_SUCCEEDED: 'BuySetup_Succeeded',
	AI_CHAT_SUBMITTED: 'AiChat_Submitted',
	DEPOSIT_SETUP_SUCCEEDED: 'DepositSetup_Succeeded',
} as const;

type EventVersion = 1 | 2;

const EVENT_VERSIONS: Record<AttributionEventName, readonly EventVersion[]> = {
	[ATTRIBUTION_EVENTS.BUY_SETUP_SUCCEEDED]: [1],
	[ATTRIBUTION_EVENTS.AI_CHAT_SUBMITTED]: [1],
	[ATTRIBUTION_EVENTS.DEPOSIT_SETUP_SUCCEEDED]: [2],
} as const;

let analyticsOverride: any;

const setAnalyticsOverride = (analytics: any) => {
	analyticsOverride = analytics;
};

type ProductEvent = {
	event: string;
	properties?: Record<string, any>;
	context?: Record<string, any>;
	metric: string;
};

type AttributionEventName = (typeof ATTRIBUTION_EVENTS)[keyof typeof ATTRIBUTION_EVENTS];

const enrichEventProperties = (
	event: string,
	properties?: Record<string, any>,
	context?: Record<string, any>
): Record<string, any> | undefined => {
	const eventVersion = context?.protocols?.event_version as EventVersion;
	const isAttributionEvent = Object.values(ATTRIBUTION_EVENTS).includes(
		event as AttributionEventName
	);
	const supportedVersions = EVENT_VERSIONS[event as AttributionEventName];

	if (isAttributionEvent && supportedVersions?.includes(eventVersion)) {
		return {
			...properties,
			...getStoredAttributions(),
		};
	}

	return properties;
};

const createWebViewContext = () => {
	return {
		originatingApp: getCookie('nativePlatform') || 'web',
		originatingAppVersion: getCookie('nativeAppVersion'),
		originatingAppId: getCookie('nativeAppID'),
	};
};

/**
 * @deprecated use `useTrack` hook instead
 */
export const track = ({ event, properties, context, metric }: ProductEvent) => {
	const analytics = analyticsOverride || getAnalyticsInstance();

	if (isProdOrStaging()) {
		datadogRum.addAction(metric, properties);
	}

	const enrichedProperties = enrichEventProperties(event, properties, context);

	analytics.track(event, enrichedProperties, {
		context: {
			...context,
			isMobileWeb: isMobileWeb(),
			...createWebViewContext(),
		},
	});
};

export default function useTrack() {
	const analytics = useAnalytics();

	useEffect(() => {
		setAnalyticsOverride(analytics);
	}, [analytics]);

	return useCallback(track, [analytics]);
}
