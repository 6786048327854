export const LINK_BANK_COMPLETE = 'LINK_BANK_COMPLETE';
export const linkBankComplete = () => ({
	type: LINK_BANK_COMPLETE,
});

export const LINK_BANK_REISSUE = 'LINK_BANK_REISSUE';
export const linkBankReissue = () => ({
	type: LINK_BANK_REISSUE,
});

export const LINK_BANK_REISSUE_COMPLETE = 'LINK_BANK_REISSUE_COMPLETE';
export const linkBankReissueComplete = () => ({
	type: LINK_BANK_REISSUE_COMPLETE,
});

export const LINK_BANK_MICROS = 'LINK_BANK_MICROS';
export const linkBankMicros = () => ({
	type: LINK_BANK_MICROS,
});

export const LINK_BANK_MICROS_SUBMIT = 'LINK_BANK_MICROS_SUBMIT';
export const linkBankMicrosSubmit = () => ({
	type: LINK_BANK_MICROS_SUBMIT,
});

export const LINK_BANK_SELECT = 'LINK_BANK_SELECT';
export const linkBankSelect = (bank, method) => ({
	type: LINK_BANK_SELECT,
	bank,
	method,
});
