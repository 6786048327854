/**
 * This middleware integrates Google's reCAPTCHA challenge
 * when a user is trying to sign up. Google loves nothing more
 * than a global callback on window so this could get a little
 * messy.
 */

import { POST_USER_REQUEST } from '../actions';
import { GOOGLE_RECAPTCHA_SITE_ID } from '../constants';
import { isProdOrStaging } from 'stash/utils'; // The middleware. Intercepts when a user is POSTed and executes

// The middleware. Intercepts when a user is POSTed and executes
// the reCAPTCHA challenge for the user.
export default () => (next) => (action) => {
	if (action.type === POST_USER_REQUEST && isProdOrStaging()) {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(GOOGLE_RECAPTCHA_SITE_ID, {
						action: 'create_user',
					})
					.then((token) => {
						action.request.body['recaptcha_version'] = 3;
						action.request.body['recaptcha_action'] = 'create_user';
						action.request.body['g-recaptcha-response'] = token;

						return next(action)
							.then((res) => {
								if (res.status === 201 && res.success) {
									return resolve(res);
								}
							})
							.catch((err) => {
								return reject(err);
							});
					});
			});
		});
	} else {
		return next(action);
	}
};
