import {
	POST_BUY_SUCCESS,
	POST_BUY_WITH_DEPOSIT_SUCCESS,
	POST_SELL_SUCCESS,
} from 'stash/actions/api/transactions';
import { POST_WITHDRAW_SUCCESS } from 'stash/actions/api/transfers';

export default (dataLayer, getState, action) => {
	const state = getState();
	const accountId = action.meta && action.meta.accountId;
	const accountType = accountId && state.entities.account[accountId].type;

	const isSignUp = window.location.pathname.match(/\/sign-up-*(\w*)\//);
	const isSignUpAddOn = window.location.pathname.match(/\/(\w*)-*sign-up\//);
	const signUpPath =
		(isSignUp && (isSignUp[1] || 'invest')) ||
		(isSignUpAddOn && isSignUpAddOn[1] && `${isSignUpAddOn[1]}AddOn`);
	const funnelType =
		signUpPath && signUpPath.charAt(0).toUpperCase() + signUpPath.slice(1);

	switch (action.type) {
		case POST_BUY_SUCCESS: {
			const transaction = action.request.body.transaction;

			const event = {
				event: 'Transaction',
				properties: {
					Type: 'Purchase',
					AccountType: accountType,
					FunnelType: funnelType,
					Amount: transaction.value,
					Card: transaction.card_id,
					Origin: window.location.pathname,
					PaymentMethod: 'StashCash',
				},
			};
			dataLayer.push(event);
			break;
		}

		case POST_BUY_WITH_DEPOSIT_SUCCESS: {
			const body = action.request.body;
			const type = body.buy || body.purchases ? 'Purchase' : 'Deposit';

			// prettier-ignore
			const amount =
                body.buy ? body.buy.amount :
                    body.deposit ? body.deposit.amount :
                        body.purchases ? body.purchases.reduce((acc, purchase) => {
                            return acc + parseFloat(purchase.amount);
                        }, 0) : 0;

			// prettier-ignore
			const card =
                action.meta.card_id ? action.meta.card_id :
                    body.buy ? body.buy.card_id :
                        body.purchases ? JSON.stringify(body.purchases) : '';

			const paymentMethod = action.meta.paymentMethod || 'Bank';

			const event = {
				event: 'Transaction',
				properties: {
					Type: type,
					AccountType: accountType,
					FunnelType: funnelType,
					Amount: amount,
					Card: card,
					Origin: window.location.pathname,
					PaymentMethod: paymentMethod,
				},
			};
			dataLayer.push(event);
			break;
		}

		case POST_SELL_SUCCESS: {
			const transaction = action.request.body.transaction;
			const type = transaction.sell_all === '1' ? 'SellAll' : 'Sell';

			const event = {
				event: 'Transaction',
				properties: {
					Type: type,
					AccountType: accountType,
					Amount: transaction.value,
					Card: transaction.card_id,
					Origin: window.location.pathname,
					PaymentMethod: '',
				},
			};
			dataLayer.push(event);
			break;
		}

		case POST_WITHDRAW_SUCCESS: {
			const transfer = action.request.body.transfer;

			const event = {
				event: 'Transaction',
				properties: {
					Type: 'Withdrawal',
					AccountType: accountType,
					Amount: parseFloat(transfer.amount),
					Card: -1,
					Origin: window.location.pathname,
					PaymentMethod: 'StashCash',
				},
			};
			dataLayer.push(event);
			break;
		}

		default:
			break;
	}
};
