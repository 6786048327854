import { fetchRequestWithTypes } from './index.js';

// Returns an account's bank accounts
// https://staging.stsh.io/documentation/1.0/bank_accounts/index.html

export const GET_ACCOUNT_BANK_ACCOUNTS_REQUEST = 'GET_ACCOUNT_BANK_ACCOUNTS_REQUEST';
export const GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS = 'GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS';
export const GET_ACCOUNT_BANK_ACCOUNTS_ERRORED = 'GET_ACCOUNT_BANK_ACCOUNTS_ERRORED';

export const getAccountBankAccounts = (accountId) =>
	fetchRequestWithTypes({
		types: [
			GET_ACCOUNT_BANK_ACCOUNTS_REQUEST,
			GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS,
			GET_ACCOUNT_BANK_ACCOUNTS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/bank_accounts`,
		},
	});

// Creates a Bank Account for an account
// https://staging.stsh.io/documentation/1.0/bank_accounts/create.html

export const POST_ACCOUNT_BANK_ACCOUNT_REQUEST = 'POST_ACCOUNT_BANK_ACCOUNT_REQUEST';
export const POST_ACCOUNT_BANK_ACCOUNT_SUCCESS = 'POST_ACCOUNT_BANK_ACCOUNT_SUCCESS';
export const POST_ACCOUNT_BANK_ACCOUNT_ERRORED = 'POST_ACCOUNT_BANK_ACCOUNT_ERRORED';

export const postMicrosBankAccount = (body) =>
	fetchRequestWithTypes({
		types: [
			POST_ACCOUNT_BANK_ACCOUNT_REQUEST,
			POST_ACCOUNT_BANK_ACCOUNT_SUCCESS,
			POST_ACCOUNT_BANK_ACCOUNT_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/externalAccounts/api/v1/users/:uuid/link/legacy-micro/new`,
			body,
		},
	});

// Submit micro-deposit values to approve a Bank Account ACH relationship
// https://staging.stsh.io/documentation/1.0/bank_accounts/approve_micro_deposits.html

export const POST_MICROS_REQUEST = 'POST_MICROS_REQUEST';
export const POST_MICROS_SUCCESS = 'POST_MICROS_SUCCESS';
export const POST_MICROS_ERRORED = 'POST_MICROS_ERRORED';

export const postMicros = (body, investAccountId) =>
	fetchRequestWithTypes({
		types: [POST_MICROS_REQUEST, POST_MICROS_SUCCESS, POST_MICROS_ERRORED],
		request: {
			method: 'POST',
			path: `/apis/externalAccounts/api/v1/users/:uuid/link/legacy-micro/${investAccountId}/verify`,
			body,
		},
	});

/**
 * Reissue micro deposits for manually linking bank account.
 * Required after a user has exceeded the number of allowed attempts to verify micro deposits,
 * or time has expired to verify micro deposits.
 */
export const POST_MICROS_REISSUE_REQUEST = 'POST_MICROS_REISSUE_REQUEST';
export const POST_MICROS_REISSUE_SUCCESS = 'POST_MICROS_REISSUE_SUCCESS';
export const POST_MICROS_REISSUE_ERRORED = 'POST_MICROS_REISSUE_ERRORED';

export const postMicrosReissue = (investAccountId) =>
	fetchRequestWithTypes({
		types: [
			POST_MICROS_REISSUE_REQUEST,
			POST_MICROS_REISSUE_SUCCESS,
			POST_MICROS_REISSUE_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/externalAccounts/api/v1/users/:uuid/link/legacy-micro/${investAccountId}/reissue`,
			body: {},
		},
	});

// FOR TEST ENVIRONMENTS ONLY Gets the two micro-deposit amounts required to approve a bank relationship.
export const GET_MICRO_AMOUNTS_REQUEST = 'GET_MICRO_AMOUNTS_REQUEST';
export const GET_MICRO_AMOUNTS_SUCCESS = 'GET_MICRO_AMOUNTS_SUCCESS';
export const GET_MICRO_AMOUNTS_ERRORED = 'GET_MICRO_AMOUNTS_ERRORED';

export const getMicroAmounts = ({ linkId }) =>
	fetchRequestWithTypes({
		types: [
			GET_MICRO_AMOUNTS_REQUEST,
			GET_MICRO_AMOUNTS_SUCCESS,
			GET_MICRO_AMOUNTS_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/externalAccounts/api/v1/users/:uuid/link/legacy-micro/${linkId}/simulate-micro-deposits`,
		},
	});

export const GET_EXTERNAL_ACCOUNT_REQUEST = 'GET_EXTERNAL_ACCOUNT_REQUEST';
export const GET_EXTERNAL_ACCOUNT_SUCCESS = 'GET_EXTERNAL_ACCOUNT_SUCCESS';
export const GET_EXTERNAL_ACCOUNT_ERRORED = 'GET_EXTERNAL_ACCOUNT_ERRORED';

export const getExternalAccount = () =>
	fetchRequestWithTypes({
		types: [
			GET_EXTERNAL_ACCOUNT_REQUEST,
			GET_EXTERNAL_ACCOUNT_SUCCESS,
			GET_EXTERNAL_ACCOUNT_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/externalAccounts/api/v1/users/:uuid/manageExternalBankAccount`,
		},
	});
