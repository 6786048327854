import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { Box } from '@stashinvest/ui';
import TopBar from 'stash/components/global/TopBar';
import ContentMoatNavBar from 'stash/components/content/ContentMoatNavbar';
import MobileNav from 'stash/components/global/MobileNav';
import { selectIsContentMoat } from 'stash/selectors';
import { useSelector } from 'react-redux';
import useNavOptions from './hooks/useNavOptions';
import { EntitlementsProvider } from 'stash/hooks/useEntitlements';

const Background = createGlobalStyle`
	body {
		background-color: ${({ theme }) => theme.colors.bgPrimary} !important;
	}
`;

const AnimatedWrapper = styled.div`
	transition: background 0.3s ease-in-out;
`;

const Dashboard = (props) => {
	const isContentMoat = useSelector(selectIsContentMoat);
	const dashboardReady = useSelector((state) => state.application.dashboard);
	const { selectedIndex, getNavOptions, onNavLinkClick } = useNavOptions();
	const navOptions = getNavOptions();
	const railNavOptions = navOptions.map((nav) => nav.railOption);

	const { location, children, history } = props;
	return (
		<EntitlementsProvider>
			<div>
				{isContentMoat && dashboardReady ? (
					<ContentMoatNavBar history={history} />
				) : (
					<TopBar
						location={location}
						navOptions={railNavOptions}
						selectedIndex={selectedIndex}
						onNavLinkClick={onNavLinkClick}
					/>
				)}
				<Background />
				<AnimatedWrapper>
					<Box m="auto">
						{!isContentMoat && (
							<MobileNav
								navOptions={railNavOptions}
								selectedIndex={selectedIndex}
								onNavLinkClick={onNavLinkClick}
							/>
						)}
						<main>{children}</main>
					</Box>
				</AnimatedWrapper>
			</div>
		</EntitlementsProvider>
	);
};

Dashboard.propTypes = {
	location: PropTypes.object,
	children: PropTypes.any,
	history: PropTypes.object,
};

export default Dashboard;
