import { fetchRequestWithTypes } from './index.js';
// Returns the subscription details for a tier user
// https://edge.stsh.io/documentation/1.0/subscription_details/show.html

export const GET_USER_SUBSCRIPTION_REQUEST = 'GET_USER_SUBSCRIPTION_REQUEST';
export const GET_USER_SUBSCRIPTION_SUCCESS = 'GET_USER_SUBSCRIPTION_SUCCESS';
export const GET_USER_SUBSCRIPTION_ERRORED = 'GET_USER_SUBSCRIPTION_ERRORED';

export const getUserSubscription = () => {
	return fetchRequestWithTypes({
		types: [
			GET_USER_SUBSCRIPTION_REQUEST,
			GET_USER_SUBSCRIPTION_SUCCESS,
			GET_USER_SUBSCRIPTION_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/subscriptions`,
		},
	});
};

export const GET_USER_BILLING_SUMMARY_REQUEST = 'GET_USER_BILLING_SUMMARY_REQUEST';
export const GET_USER_BILLING_SUMMARY_SUCCESS = 'GET_USER_BILLING_SUMMARY_SUCCESS';
export const GET_USER_BILLING_SUMMARY_ERRORED = 'GET_USER_BILLING_SUMMARY_ERRORED';

export const getUserBillingSummary = () => {
	return fetchRequestWithTypes({
		types: [
			GET_USER_BILLING_SUMMARY_REQUEST,
			GET_USER_BILLING_SUMMARY_SUCCESS,
			GET_USER_BILLING_SUMMARY_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/customers/v1/users/:uuid/subscriptions/billingSummaries`,
		},
	});
};

export const GET_BILLING_FREQUENCY_OPTIONS_REQUEST =
	'GET_BILLING_FREQUENCY_OPTIONS_REQUEST';
export const GET_BILLING_FREQUENCY_OPTIONS_SUCCESS =
	'GET_BILLING_FREQUENCY_OPTIONS_SUCCESS';
export const GET_BILLING_FREQUENCY_OPTIONS_ERRORED =
	'GET_BILLING_FREQUENCY_OPTIONS_ERRORED';

export const getBillingFrequencyOptions = () => {
	return fetchRequestWithTypes({
		types: [
			GET_BILLING_FREQUENCY_OPTIONS_REQUEST,
			GET_BILLING_FREQUENCY_OPTIONS_SUCCESS,
			GET_BILLING_FREQUENCY_OPTIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/customers/v1/users/:uuid/subscriptions/billingFrequencies',
		},
	});
};

export const GET_REFUND_POLICY_REQUEST = 'GET_REFUND_POLICY_REQUEST';
export const GET_REFUND_POLICY_SUCCESS = 'GET_REFUND_POLICY_SUCCESS';
export const GET_REFUND_POLICY_ERRORED = 'GET_REFUND_POLICY_ERRORED';

export const getRefundPolicy = () => {
	return fetchRequestWithTypes({
		types: [
			GET_REFUND_POLICY_REQUEST,
			GET_REFUND_POLICY_SUCCESS,
			GET_REFUND_POLICY_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/customers/v1/users/:uuid/subscriptions/policies/refunds',
		},
	});
};

export const PUT_BILLING_FREQUENCY_REQUEST = 'PUT_BILLING_FREQUENCY_REQUEST';
export const PUT_BILLING_FREQUENCY_SUCCESS = 'PUT_BILLING_FREQUENCY_SUCCESS';
export const PUT_BILLING_FREQUENCY_ERRORED = 'PUT_BILLING_FREQUENCY_ERRORED';

export const changeBillingFrequency = (frequency) => {
	return fetchRequestWithTypes({
		types: [
			PUT_BILLING_FREQUENCY_REQUEST,
			PUT_BILLING_FREQUENCY_SUCCESS,
			PUT_BILLING_FREQUENCY_ERRORED,
		],
		request: {
			method: 'PUT',
			path: '/apis/customers/v1/users/:uuid/subscriptions/billingFrequencies',
			body: { frequency },
		},
	});
};

export const GET_USER_PAYMENT_METHODS_REQUEST = 'GET_USER_PAYMENT_METHODS_REQUEST';
export const GET_USER_PAYMENT_METHODS_SUCCESS = 'GET_USER_PAYMENT_METHODS_SUCCESS';
export const GET_USER_PAYMENT_METHODS_ERRORED = 'GET_USER_PAYMENT_METHODS_ERRORED';

export const getUserPaymentMethods = () =>
	fetchRequestWithTypes({
		types: [
			GET_USER_PAYMENT_METHODS_REQUEST,
			GET_USER_PAYMENT_METHODS_SUCCESS,
			GET_USER_PAYMENT_METHODS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/customers/v1/users/:uuid/subscriptions/paymentMethods`,
		},
	});

export const PUT_USER_PAYMENT_METHOD_PREFERENCE_REQUEST =
	'PUT_USER_PAYMENT_METHOD_PREFERENCE_REQUEST';
export const PUT_USER_PAYMENT_METHOD_PREFERENCE_SUCCESS =
	'PUT_USER_PAYMENT_METHOD_PREFERENCE_SUCCESS';
export const PUT_USER_PAYMENT_METHOD_PREFERENCE_ERRORED =
	'PUT_USER_PAYMENT_METHOD_PREFERENCE_ERRORED';

export const putUserPaymentMethodPreference = (id, type) =>
	fetchRequestWithTypes({
		types: [
			PUT_USER_PAYMENT_METHOD_PREFERENCE_REQUEST,
			PUT_USER_PAYMENT_METHOD_PREFERENCE_SUCCESS,
			PUT_USER_PAYMENT_METHOD_PREFERENCE_ERRORED,
		],
		request: {
			method: 'PUT',
			path: `/apis/customers/v1/users/:uuid/subscriptions/paymentMethodPreferences`,
			body: {
				paymentMethod: {
					id,
					type,
				},
			},
		},
	});
