import { fetchRequestWithTypes } from './index.js';

export const GET_QUOTE_RANGE_REQUEST = 'GET_QUOTE_RANGE_REQUEST';
export const GET_QUOTE_RANGE_SUCCESS = 'GET_QUOTE_RANGE_SUCCESS';
export const GET_QUOTE_RANGE_ERRORED = 'GET_QUOTE_RANGE_ERRORED';

export const getQuoteRange = (cardId, timePeriod) => {
	return fetchRequestWithTypes({
		types: [GET_QUOTE_RANGE_REQUEST, GET_QUOTE_RANGE_SUCCESS, GET_QUOTE_RANGE_ERRORED],
		cardId,
		timePeriod,
		request: {
			method: 'GET',
			path: `/apis/api/v1/historical_quotes/quotes_ranges?card_id=${cardId}&time_period=${timePeriod}`,
		},
	});
};
