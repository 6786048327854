// Public keys and env constants for React
// Do NOT store private keys here.
import { generateRandomString, isProd } from 'stash/utils';

export const APP_HOSTNAME = window.location.hostname;

export const APP_STORE_URL =
	'https://itunes.apple.com/us/app/stash-invest-investing-financial/id1017148055';

export const GOOGLE_PLAY_URL =
	'https://play.google.com/store/apps/details?id=com.stash.stashinvest';

export const GOOGLE_API_KEY = 'AIzaSyB2YrG2RJrFZXpoEdDSJDm7lwtCF6Dj2yI';

export const GOOGLE_RECAPTCHA_SITE_ID = isProd()
	? '6LdsJ9oUAAAAAMUQ5m2PYLEZT9CyQvbqBCDwfB-3'
	: '6LfPJtsUAAAAAHW2utF4dUPktbLGv3tUoyjhCYfA';

export const TABAPAY_IFRAME_ORIGIN_V2 = isProd()
	? `https://sso.tabapay.com/SSOStashISOV2.html?${generateRandomString(6)}`
	: `https://sso.sandbox.tabapay.com:8443/SSOStashInvestV2.html?${generateRandomString(
			6
	  )}`;

export const TABAPAY_IFRAME_ORIGIN_DARK_V2 = isProd()
	? `https://sso.tabapay.com/SSOStashISOV2.html?${generateRandomString(6)}&theme=dark`
	: `https://sso.sandbox.tabapay.com:8443/SSOStashInvestV2.html?${generateRandomString(
			6
	  )}&theme=dark`;

export const getThemedTabapayIframeOriginV2 = (mode) => {
	return mode === 'dark' ? TABAPAY_IFRAME_ORIGIN_DARK_V2 : TABAPAY_IFRAME_ORIGIN_V2;
};

export const CONTACT_URL = 'https://ask.stash.com/contact?from=stash-contact-web';

const MATCH_THE_MARKET_CARD_ID = {
	edge: '117',
	staging: '85',
	prod: '83',
};

export const getMatchTheMarketIDforEnv = () => {
	return MATCH_THE_MARKET_CARD_ID[window.Stash.env] || MATCH_THE_MARKET_CARD_ID.edge;
};

export const WEB_REG_DOMAINS = {
	__STASH_ENV__: 'https://app-local.stash.com:9009',
	edge: 'https://app-edge.stash.com',
	staging: 'https://app-staging.stash.com',
	production: 'https://app.stash.com',
};
export const WEB_REG_PATH = WEB_REG_DOMAINS[window.Stash.env]
	? `${WEB_REG_DOMAINS[window.Stash.env]}/sign-up/get-started`
	: 'https://app.stash.com/sign-up/get-started';

export const STASH_WORKS_WEB_DOMAINS = {
	__STASH_ENV__: 'https://works-edge.stash.com',
	edge: 'https://works-edge.stash.com',
	staging: 'https://works-staging.stash.com',
	production: 'https://works.stash.com',
};
export const STASH_WORKS_WEB_PATH = STASH_WORKS_WEB_DOMAINS[window.Stash.env]
	? `${STASH_WORKS_WEB_DOMAINS[window.Stash.env]}/`
	: 'https://works.stash.com/';

export const BRAZE_API_KEYS = {
	__STASH_ENV__: 'c5ddb9e3-eef6-4fed-9cb9-f9d509a8e09b',
	edge: 'c5ddb9e3-eef6-4fed-9cb9-f9d509a8e09b',
	staging: '71380541-1bc1-4cf6-846d-799b1c824a3e',
	production: '0da29a32-e4a8-451c-83cd-f0110502629b',
};
export const BRAZE_ENV_APP_KEY = BRAZE_API_KEYS[window.Stash.env] || BRAZE_API_KEYS.prod;
export const BRAZE_SDK_ENDPOINT = 'sdk.iad-01.braze.com';

export * from './disclosures';
