import { GET_PX_TAX_DOCUMENTS_SUCCESS } from '../../actions';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_PX_TAX_DOCUMENTS_SUCCESS:
			return {
				...state,
				[action.response.accountId]: {
					...state[action.response.accountId],
					px_documents: action.response.documents || [],
				},
			};
		default:
			return state;
	}
};
