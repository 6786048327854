import {
	DELETE_ACCOUNT_AUTO_STASH_SUCCESS,
	DELETE_AUTO_STASH_ALLOCATION_SUCCESS,
	GET_ACCOUNT_AUTO_STASH_SUCCESS,
	GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS,
	GET_ACCOUNT_CARDS_SUCCESS,
	GET_ACCOUNT_PORTFOLIO_SUCCESS,
	GET_ACCOUNT_SUCCESS,
	GET_LAUNCH_INFO_SUCCESS,
	GET_USER_SUCCESS,
	PATCH_CANCEL_ACCOUNT_CLOSE_SUCCESS,
	PATCH_CUSTODIAN_ACCOUNT_SUCCESS,
	PAUSE_SET_SCHEDULE_SUCCESS,
	POST_ACCOUNT_AUTO_STASH_SUCCESS,
	POST_ACCOUNT_BANK_ACCOUNT_SUCCESS,
	POST_ACCOUNT_CLOSE_SUCCESS,
	POST_ACCOUNT_SUBMIT_SUCCESS,
	POST_ACCOUNT_SUCCESS,
	POST_AUTO_STASH_ALLOCATION_SUCCESS,
	POST_USER_SUCCESS,
	UPDATE_CARD_POSITIONS,
} from '../../actions';
import { PITHY_AUTO_STASH_FREQUENCY_TEXT } from '../../constants/mappings';
import { camelToSnakeCase } from 'stash/reducers/entities/cards';

export const formatAutoStash = (autoStash) => {
	return autoStash && autoStash.frequency
		? {
				...autoStash,
				frequency_formatted: PITHY_AUTO_STASH_FREQUENCY_TEXT[autoStash.frequency],
		  }
		: {};
};

const accountReducer = (state = {}, action) => {
	switch (action.type) {
		case POST_USER_SUCCESS:
		case GET_USER_SUCCESS:
		case GET_LAUNCH_INFO_SUCCESS:
			return action.response.accounts.reduce(
				(accumulator, account) => ({
					...accumulator,
					[account.id]: {
						...state[account.id],
						...account,
					},
				}),
				{}
			);

		case GET_ACCOUNT_SUCCESS: {
			const id = action.response.account_id;

			return !id
				? state
				: {
						...state,
						[id]: {
							...state[id],
							...action.response.account,
						},
				  };
		}

		case GET_ACCOUNT_PORTFOLIO_SUCCESS: {
			const id = action.response.account_id;
			return !id
				? state
				: {
						...state,
						[id]: {
							...state[id],
							portfolio: action.response.portfolio,
						},
				  };
		}

		case GET_ACCOUNT_CARDS_SUCCESS: {
			const id = action.response.account_id;

			const cards = {
				...action.response.cards.reduce(
					(acc, card) => ({
						...acc,
						[card.id]: {
							...card,
						},
					}),
					{}
				),
			};

			return !id
				? state
				: {
						...state,
						[id]: {
							...state[id],
							cards,
						},
				  };
		}

		case UPDATE_CARD_POSITIONS: {
			const card = action.response.card;
			const accountPositions = action.response.accounts?.filter((a) => a.position);

			if (!accountPositions?.length) return state;

			return accountPositions.reduce(
				(newState, account) => {
					const cards = newState[account.id]?.cards;
					const accountCard = cards?.[card.id];

					newState[account.id] = {
						...newState[account.id],
						cards: {
							...cards,
							[card.id]: {
								...accountCard,
								...camelToSnakeCase(card),
								id: parseInt(card.id, 10), // spacejam sends back a string id when it should be stored as an int
								user_investment: camelToSnakeCase(account.position),
							},
						},
					};

					return newState;
				},
				{ ...state }
			);
		}

		case POST_ACCOUNT_BANK_ACCOUNT_SUCCESS:
		case GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS: {
			const id = action.response.account_id;

			return !id
				? state
				: {
						...state,
						[id]: {
							...state[id],
							bank_account: action.response.bank_account || {},
						},
				  };
		}

		case GET_ACCOUNT_AUTO_STASH_SUCCESS:
		case PAUSE_SET_SCHEDULE_SUCCESS:
		case POST_ACCOUNT_AUTO_STASH_SUCCESS: {
			const id = action.response.account_id;
			return !id
				? state
				: {
						...state,
						[id]: {
							...state[id],
							auto_stash: formatAutoStash(action.response.auto_stash),
						},
				  };
		}

		case POST_AUTO_STASH_ALLOCATION_SUCCESS: {
			const account_id = action.meta.accountId;
			const account = state[account_id];
			const auto_stash = account.auto_stash;
			const newAllocation = action.response.auto_stash_allocation;

			const newAllocationIndex = auto_stash.allocations.findIndex((allocation) => {
				return allocation.card_id === newAllocation.card_id;
			});

			const newAllocations =
				newAllocationIndex > -1
					? [
							...auto_stash.allocations.slice(0, newAllocationIndex),
							newAllocation,
							...auto_stash.allocations.slice(newAllocationIndex + 1),
					  ]
					: [...auto_stash.allocations, newAllocation];

			// TODO: Needs to update total_allocated_amount.
			return {
				...state,
				[account_id]: {
					...state[account_id],
					auto_stash: {
						...state[account_id].auto_stash,
						allocations: newAllocations,
					},
				},
			};
		}

		case DELETE_ACCOUNT_AUTO_STASH_SUCCESS: {
			const accountId = action.meta.accountId;

			return {
				...state,
				[accountId]: {
					...state[accountId],
					auto_stash: {},
				},
			};
		}

		case DELETE_AUTO_STASH_ALLOCATION_SUCCESS: {
			const { accountId, cardId } = action.meta;
			const newAllocations = state[accountId].auto_stash.allocations?.filter(
				(allocation) => allocation.card_id !== parseInt(cardId, 10)
			);

			return {
				...state,
				[accountId]: {
					...state[accountId],
					auto_stash: {
						...state[accountId].auto_stash,
						allocations: newAllocations,
					},
				},
			};
		}

		case POST_ACCOUNT_SUCCESS:
		case POST_ACCOUNT_SUBMIT_SUCCESS:
		case PATCH_CUSTODIAN_ACCOUNT_SUCCESS: {
			const account = action.response.account;

			return {
				...state,
				[account.id]: account,
			};
		}

		case PATCH_CANCEL_ACCOUNT_CLOSE_SUCCESS: {
			return {
				...state,
				[action.response.account_id]: {
					...state[action.response.account_id],
					state: 'complete',
				},
			};
		}

		case POST_ACCOUNT_CLOSE_SUCCESS: {
			return {
				...state,
				[action.response.account_id]: {
					...state[action.response.account_id],
					state: 'in_closing_process',
				},
			};
		}
		default:
			return state;
	}
};

export default accountReducer;
