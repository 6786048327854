import { Layout, ListView, Tile } from '@stashinvest/ui';
import React from 'react';

export const StatementsTile = () => {
	return (
		<Layout.ContentWrap mb="xl">
			<Tile>
				<ListView
					variant={2}
					as="div"
					p="none"
					href="/documents/bankStatements"
					title="Looking for your statements?"
					subtitle="See past statements from your banking account."
					hideBottomDivider
				/>
			</Tile>
		</Layout.ContentWrap>
	);
};
