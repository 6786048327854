import { GET_CARD_BY_ID_SUCCESS, GET_CARD_DETAIL_SUCCESS } from 'stash/actions/api/card';

const cardsByTicker = (state = {}, action) => {
	switch (action.type) {
		case GET_CARD_BY_ID_SUCCESS: {
			const card = action.response.card;

			return {
				...state,
				[card.ticker_symbol]: card.id,
			};
		}

		case GET_CARD_DETAIL_SUCCESS: {
			const card = action.response.card;

			return {
				...state,
				[card.tickerSymbol]: card.id,
			};
		}

		default:
			return state;
	}
};

export default cardsByTicker;
