import React, { createContext, useContext } from 'react';
import { useCachedGet } from '@stashinvest/use-fetch';
import { curry } from 'lodash';

type EntitlementURI =
	| 'feature:invest:account:custodial:self'
	| 'feature:invest:account:custodial:robo'
	| 'feature:invest:account:retirement:self'
	| 'feature:invest:account:personal:robo'
	| 'feature:content:report:standard'
	| 'feature:content:ai:money-coach'
	| 'feature:solutions:insurance:avibra:31000offer'
	| 'feature:content:content-moat'
	| 'feature:bank:card:premium'
	| 'feature:bank:stockback:merchant-bonus:premium'
	| 'feature:content:report:premium';

export type EntitlementRecord = {
	uri: EntitlementURI;
};

export const OPEN_ACCOUNT_CUSTODIAL_SELF: EntitlementURI =
	'feature:invest:account:custodial:self';
export const OPEN_ACCOUNT_CUSTODIAL_ROBO: EntitlementURI =
	'feature:invest:account:custodial:robo';
export const OPEN_ACCOUNT_RETIREMENT_SELF: EntitlementURI =
	'feature:invest:account:retirement:self';
export const OPEN_ACCOUNT_PERSONAL_ROBO: EntitlementURI =
	'feature:invest:account:personal:robo';
export const VIEW_CONTENT_REPORT_STANDARD: EntitlementURI =
	'feature:content:report:standard';
export const VIEW_CONTENT_AI_MONEY_COACH: EntitlementURI =
	'feature:content:ai:money-coach';
export const ACCESS_SOLUTION_INSURANCE_AVIBRA_31000OFFER: EntitlementURI =
	'feature:solutions:insurance:avibra:31000offer';
export const VIEW_CONTENT_CORE: EntitlementURI = 'feature:content:content-moat';
export const STOCK_BACK_PREMIUM: EntitlementURI = 'feature:bank:card:premium';
export const STOCK_BACK_MERCHANT_BONUS_PREMIUM: EntitlementURI = 'feature:bank:stockback:merchant-bonus:premium';
export const VIEW_CONTENT_REPORT_PREMIUM: EntitlementURI =
	'feature:content:report:premium';

const hasEntitlement = curry(
	(entitlements: EntitlementRecord[], entitlementUri: EntitlementURI) => {
		return entitlements.some((e) => e.uri === entitlementUri);
	}
);

type EntitlementsContextType = {
	isEntitlementsLoading: boolean;
	entitlementsError: Error | null;
	hasEntitlement: (entitlementUri: EntitlementURI) => boolean;
	revalidateEntitlements: () => Promise<void>;
};

const EntitlementsContext = createContext<EntitlementsContextType | null>(null);

type Props = {
	children: React.ReactNode;
};

// This provider allows us to cache the entitlements across the app and revalidate them when needed
// currently we revalidate every 5 minutes and/or when the user changes their plan.
// There may be ways to configure the swr cache to do this more efficiently, but this is the simplest
// way without making significant changes to use-fetch.
export function EntitlementsProvider({ children }: Props) {
	const { data, loading, mutate, error } = useCachedGet<{
		entitlements: EntitlementRecord[];
	}>({
		path: '/apis/customers/v1/subscriptions/entitlements',
		onMount: true,
		swrConfig: {
			refreshInterval: 5 * 60 * 1000,
		},
	});

	const entitlements = data?.entitlements as EntitlementRecord[] | undefined;

	return (
		<EntitlementsContext.Provider
			value={{
				isEntitlementsLoading: loading,
				entitlementsError: error,
				hasEntitlement: entitlements ? hasEntitlement(entitlements) : () => false,
				revalidateEntitlements: () => mutate(),
			}}
		>
			{children}
		</EntitlementsContext.Provider>
	);
}

export const useEntitlements = () => {
	const context = useContext(EntitlementsContext);

	if (!context) {
		throw new Error('useEntitlements must be used within an EntitlementsProvider');
	}

	return context;
};
