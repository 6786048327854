import { ATTRIBUTION_PROPERTIES } from 'stash/constants/session';

export const getStoredAttributions = () => {
	try {
		return JSON.parse(sessionStorage.getItem(ATTRIBUTION_PROPERTIES) || '{}');
	} catch (e) {
		console.error('Failed to parse attribution properties:', e);
		return {};
	}
};

export const setStoredAttributions = (properties) => {
	try {
		sessionStorage.setItem(ATTRIBUTION_PROPERTIES, JSON.stringify(properties));
	} catch (e) {
		console.error('Failed to store attribution properties:', e);
	}
};
