import { isMobile } from 'stash/utils';

export default [
	['/reactivate-account', 'ReactivateAccount'],
	['/reactivate-success', 'ReactivateSuccess'],
	['/account-locked', 'AccountLocked'],
	['/account-verify', 'AccountVerify'],

	// WIMAL 1
	['/account-verify/choose-document', 'ChooseDocument'],
	//Bellow tutorial, take-a-photo & upload-preview share the same key on desktop in order to
	// change state in a single component 'Tutorial' without remounting the component.
	['/account-verify/tutorial/:documentType', 'Tutorial'],

	[
		'/account-verify/choose-a-photo/:documentType',
		'Tutorial',
		{ key: !isMobile() && '/account-verify/tutorial/:documentType' },
	],
	[
		'/account-verify/upload-preview/:documentType',
		isMobile() ? 'UploadPreview' : 'Tutorial',
		{ key: !isMobile() && '/account-verify/tutorial/:documentType' },
	],
	['/account-verify/take-a-photo/:documentType', 'TakeAPhoto'],
	['/account-verify/recap', 'Recap'],
	['/account-verify/thanks', 'Thanks'],
	['/account-verify/document-upload-complete', 'DocumentUploadComplete'],

	// WIMAL2
	['/account-verify/name', 'Name'],
	['/account-verify/dob', 'DOB'],
	['/account-verify/ssn', 'SSN'],
	['/account-verify/address', 'Address'],
];
