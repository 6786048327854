import { isProdOrStaging } from 'stash/utils';
import { getCookie } from 'stash/utils/cookies';

export function sendMixpanel(event, properties) {
	if (!isProdOrStaging() && getCookie('stashEventLogger')) {
		console.info(`[${event}]`, { event, properties });
	}

	if (!window.dataLayer) {
		console.log('GTM dataLayer not found');
		return;
	}

	return window.dataLayer.push({
		event,
		properties: {
			Action: undefined,
			ScreenName: undefined,
			TileType: undefined,
			ActionLink: undefined,
			...properties,
		},
	});
}
