import { fetchRequestWithTypes } from './index.js';

// Creates a withdrawal transfer

export const POST_WITHDRAW_REQUEST = 'POST_WITHDRAW_REQUEST';
export const POST_WITHDRAW_SUCCESS = 'POST_WITHDRAW_SUCCESS';
export const POST_WITHDRAW_ERRORED = 'POST_WITHDRAW_ERRORED';

export const postWithdraw = (accountId, amount) =>
	fetchRequestWithTypes({
		types: [POST_WITHDRAW_REQUEST, POST_WITHDRAW_SUCCESS, POST_WITHDRAW_ERRORED],
		meta: {
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transfers/withdraw`,
			body: {
				transfer: {
					amount,
				},
			},
		},
	});

// Creates a multi-account funding deposits with recurring transfers.
// https://github.com/StashInvest/stash-transfer-router/blob/edge/docs/api/swagger.yml#L145
export const POST_REGISTRATION_TRANSFER_REQUEST = 'POST_REGISTRATION_TRANSFER';
export const POST_REGISTRATION_TRANSFER_SUCCESS = 'POST_REGISTRATION_TRANSFER_SUCCESS';
export const POST_REGISTRATION_TRANSFER_ERRORED = 'POST_REGISTRATION_TRANSFER_ERRORED';

export const postRegistrationTransfer = (accountsToFund) => {
	const initialTransfers = Object.values(accountsToFund)
		.filter((accountToFund) => {
			return parseFloat(accountToFund.amount) > 0;
		})
		.map((accountToFund) => {
			return {
				destinationAccountType: accountToFund.typeEnum,
				frequency: accountToFund.frequency,
				amount: {
					value: accountToFund.amount,
					currency: 'USD',
				},
			};
		});

	const body = {
		initialTransfers,
	};

	return fetchRequestWithTypes({
		types: [
			POST_REGISTRATION_TRANSFER_REQUEST,
			POST_REGISTRATION_TRANSFER_SUCCESS,
			POST_REGISTRATION_TRANSFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/moneyMovements/api/v1/users/:uuid/transfers/registration/submit`,
			body,
		},
	});
};
