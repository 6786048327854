import { isAHoliday } from '@18f/us-federal-holidays';
import {
	addWeeks,
	addMonths,
	addYears,
	addDays,
	addMinutes,
	addBusinessDays,
	subDays,
	subWeeks,
	subMonths,
	subYears,
	getYear,
	formatDistanceToNow,
	differenceInYears,
	differenceInMonths,
	startOfTomorrow,
	startOfMonth,
	endOfMonth,
	isSameDay,
	isBefore,
	isAfter,
	isValid,
	format,
	parse,
	parseISO,
	isWithinInterval,
	differenceInDays,
	isWeekend,
	isToday,
	isTomorrow,
} from 'date-fns';

// -----------------------------------------------------------------------------
// Private functions
// -----------------------------------------------------------------------------

const _getPreciseAge = (dateOfBirth) => {
	const months = differenceInMonths(new Date(), dateOfBirth);
	return Math.max(months, 0) / 12;
};

const _formatDate = (date, template) => {
	return format(date, template);
};

// -----------------------------------------------------------------------------
// Custom date helpers
// -----------------------------------------------------------------------------

export const getAge = (dateOfBirth, precise = false) => {
	const dobObj = parseDate(dateOfBirth) || new Date();

	if (precise) {
		return _getPreciseAge(dobObj);
	}

	const years = differenceInYears(new Date(), dobObj);

	// normalize since it's possible to get -0
	return Math.max(years, 0);
};

export const getExactAge = (dateOfBirth) => getAge(dateOfBirth, true);

export const getTomorrow = () => startOfTomorrow();

export const getNextWeek = () => {
	return addWeeks(new Date(), 1);
};

export const dateFromNow = (date, includeSeconds = false) => {
	return formatDistanceToNow(date, { includeSeconds });
};

export const getFormattedDateString = (date, template = 'MMMM do, yyyy') => {
	return _formatDate(date, template);
};

export const getISOString = (date) => {
	return _formatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

export const getDateString = (date) => {
	return _formatDate(date, 'yyyy-MM-dd');
};

export function parseDate(date, format, fallbackDate) {
	if (format) {
		return parse(date, format, fallbackDate || new Date());
	}

	if (typeof date === 'string') {
		return parseISO(date);
	}

	return date;
}

export const isDateBefore = (date, dateToCompare) => {
	return isBefore(date, dateToCompare);
};

export const isDateAfter = (date, dateToCompare) => {
	return isAfter(date, dateToCompare);
};

export const isDateValid = (date) => {
	return isValid(date);
};

export const getFormattedReminderDate = (date) => {
	return format(date, 'MM/dd/yyyy');
};

export function formatDate(date, formatString = 'yyyy-MM-dd') {
	return format(date, formatString);
}

export const shiftHolidays = {
	shiftSaturdayHolidays: true,
	shiftSundayHolidays: true,
};

export function addBusinessDaysAndHolidays(date, days) {
	let newDay = date;
	let remainingDays = days;

	while (remainingDays > 0) {
		newDay = addDays(newDay, 1);
		if (!isWeekend(newDay) && !isAHoliday(newDay, shiftHolidays)) {
			remainingDays = remainingDays - 1;
		}
	}

	return newDay;
}

/** If the date is today, formats local date to string like "Today, Jan 28".
 *  If the date is tomorrow, formats local date to string like "Tomorrow, Jan 28".
 *  If the date is any other day, formats local date to string like Tuesday, Jan 28". */
export const formatTodayTomorrowOrShortMonthDay = (date) => {
	if (typeof date === 'string') {
		date = parseISO(date);
	}
	let format = 'eeee, MMM d';
	if (isToday(date)) {
		format = "'Today,' MMM d";
	} else if (isTomorrow(date)) {
		format = "'Tomorrow,' MMM d";
	}
	return formatDate(date, format);
};
// -----------------------------------------------------------------------------
//  date utils re-exported directly from date-fns
// -----------------------------------------------------------------------------

export {
	isSameDay,
	addWeeks,
	addMonths,
	addYears,
	addDays,
	addMinutes,
	addBusinessDays,
	subDays,
	subWeeks,
	subMonths,
	subYears,
	getYear,
	startOfMonth,
	endOfMonth,
	isWithinInterval,
	differenceInDays,
	format,
	parse,
	isWeekend,
};
