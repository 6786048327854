import {
	DELETE_BOOKMARK_SUCCESS,
	GET_BOOKMARKS_SUCCESS,
	GET_CARD_BY_ID_SUCCESS,
	GET_CARD_DETAIL_SUCCESS,
	POST_BOOKMARK_SUCCESS,
} from 'stash/actions/api';

// Converts the spacejam camelCase response to snake_case to ensure
// compatability across the app.
export const camelToSnakeCase = (card) => {
	const customKeyMaps = {
		icon65x65_1x: 'icon_65x65_1x',
		icon65x65_2x: 'icon_65x65_2x',
		icon65x65_3x: 'icon_65x65_3x',
		icon90x90_1x: 'icon_90x90_1x',
		icon90x90_2x: 'icon_90x90_2x',
		icon90x90_3x: 'icon_90x90_3x',
		icon375x170_1x: 'icon_375x170_1x',
		icon375x170_2x: 'icon_375x170_2x',
		icon375x170_3x: 'icon_375x170_3x',
	};

	function convertKey(key) {
		return customKeyMaps[key] || key.replace(/([A-Z])/g, '_$1').toLowerCase();
	}

	return Object.keys(card).reduce((newCard, key) => {
		newCard[convertKey(key)] = card[key];
		return newCard;
	}, {});
};

const cards = (state = {}, action) => {
	switch (action.type) {
		case GET_CARD_BY_ID_SUCCESS: {
			const card = action.response.card;
			return {
				...state,
				[card.id]: {
					...state[card.id],
					...card,
				},
			};
		}

		case GET_CARD_DETAIL_SUCCESS: {
			const card = action.response.card;
			return {
				...state,
				[card.id]: {
					...state[card.id],
					...camelToSnakeCase(card),
					id: parseInt(card.id, 10), // spacejam sends back a string id when it should be stored as an int
				},
			};
		}

		case GET_BOOKMARKS_SUCCESS: {
			return {
				...state,
				...action.response.cards.reduce((cards, c) => {
					cards[c.id] = {
						...state[c.id],
						bookmarked: c.bookmarked,
					};
					return cards;
				}, {}),
			};
		}

		case POST_BOOKMARK_SUCCESS: {
			const cardId = action.response.bookmark.card_id;

			return {
				...state,
				[cardId]: {
					...state[cardId],
					bookmarked: true,
				},
			};
		}

		case DELETE_BOOKMARK_SUCCESS: {
			const cardId = action.meta.cardId;

			return {
				...state,
				[cardId]: {
					...state[cardId],
					bookmarked: false,
				},
			};
		}

		default:
			return state;
	}
};

export default cards;
