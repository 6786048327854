import { SET_ACTIVE_NAV_TAB } from '../../actions';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_ACTIVE_NAV_TAB:
			return {
				...state,
				activeTab: action.activeTab,
			};

		default:
			return state;
	}
};
