import { fetchRequestWithTypes } from './index.js'; // GETs a user's first cards based on their initial investor application

// GETs card by id [PRIVATE]

export const GET_CARD_BY_ID_REQUEST = 'GET_CARD_BY_ID_REQUEST';
export const GET_CARD_BY_ID_SUCCESS = 'GET_CARD_BY_ID_SUCCESS';
export const GET_CARD_BY_ID_ERRORED = 'GET_CARD_BY_ID_ERRORED';

export const getCardById = (card_id) => {
	return fetchRequestWithTypes({
		types: [GET_CARD_BY_ID_REQUEST, GET_CARD_BY_ID_SUCCESS, GET_CARD_BY_ID_ERRORED],
		request: {
			method: 'GET',
			path: `/apis/api/v1/cards/${card_id}`,
		},
	});
};

export const GET_CARD_DETAIL_SUCCESS = 'GET_CARD_DETAIL_SUCCESS';

export const updateCard = (card) => {
	return {
		type: GET_CARD_DETAIL_SUCCESS,
		response: {
			card,
		},
	};
};

export const UPDATE_CARD_POSITIONS = 'UPDATE_CARD_POSITIONS';

export const updateCardPositions = (card, accounts) => {
	return {
		type: UPDATE_CARD_POSITIONS,
		response: {
			card,
			accounts,
		},
	};
};
