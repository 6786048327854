import { getCookie } from 'stash/utils/cookies';

export const getInstrumentationProperties = () => {
	const fbclid = getCookie('fbclid');
	const gclid = getCookie('gclid');
	const irclickid = getCookie('irclickid');
	const utm_medium = getCookie('utm_medium');
	const utm_source = getCookie('utm_source');
	const utm_campaign = getCookie('utm_campaign');

	return {
		...(fbclid && { fbclid: `fb.1.${Date.now()}.${fbclid}` }),
		...(gclid && { gclid }),
		...(irclickid && { irclickid }),
		...(utm_medium && { utm_medium }),
		...(utm_source && { utm_source }),
		...(utm_campaign && { utm_campaign }),
		frontend_platform: 'web',
	};
};
