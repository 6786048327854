// Launch Info Reference Doc
// https://docs.google.com/document/d/1oPeFixBmG4mZ4CaM6R5P4iAWWj7FO5oX2BRHVZJLe9U/edit

export const REG_INITIAL = 'REG_INITIAL';
export const REG_INITIAL_SHORT = 'REG_INITIAL_SHORT';
export const REG_CONTENT_HOME = 'REG_CONTENT_HOME';
export const REG_STATE_ZERO = 'REG_STATE_ZERO';
export const HOME = 'HOME';
export const STATE_ZERO_OID = 'state-zero';

export const PX_QUESTIONS_ACCOUNT_TYPE = {
	PERSONAL_BROKERAGE_ACCOUNT: 'PERSONAL_BROKERAGE_ACCOUNT',
	ROBO_PERSONAL_BROKERAGE_ACCOUNT: 'ROBO_PERSONAL_BROKERAGE_ACCOUNT',
	COMPLETE: 'COMPLETE',
};

export const LAUNCH_INFO = {
	[REG_INITIAL]: {
		description: 'No Personal Brokerage (PB) Account E-Sign',
		route: '/sign-up',
	},
	[REG_INITIAL_SHORT]: {
		description: 'No Stash Advisory E-Sign, No PB Account E-Sign',
		route: '/sign-up',
	},
	[REG_CONTENT_HOME]: {
		description: 'The Content Moat. Has Stash Advisory E-Sign, No PB Account E-Sign',
		route: '/subscriber-advice/welcome',
	},
	[REG_STATE_ZERO]: {
		route: '/sign-up/state-zero',
	},
};

/* These are the authorized dashboard URLs that users are able to see in the content moat.
   The below structure does NOT support urls with query params 
*/
export const authorizedContentMoatUrls = {
	'/account-settings': true,
	'/market-insights-report': true,
	'/subscription': true,
	'/accessibility-settings': true,
	'/plan-details': true, // /subscription and /plan-details point to same screen
	'/data-sharing': true,
	'/manage-subscription/link-payment-method': true,
	'/payment/add-card': true,
	'/add-payment-method': true,
	'/change-payment-method': true,
	'/bank-accounts/new': true,
};

/* These are the authorized dashboard URLs that users are able to see in Home.
   The below structure supports urls with query params (ex: pathname = /cards/:cardUuid/:viewType)
*/
export const authorizedHomeUrls = ['/cards'];

export const isAllowedToRenderWebApp = () => {
	// Check if current pathname is any of the authorized Content moat urls and return true
	if (authorizedContentMoatUrls[window.location.pathname]) {
		return true;
	}

	// Check if current pathname starts with one of the authorized Home urls and return true
	const authorizedHomeUrlsIterator = authorizedHomeUrls.values();
	for (const url of authorizedHomeUrlsIterator) {
		if (window.location.pathname.startsWith(url)) {
			return true;
		}
	}

	// For all other urls return false
	return false;
};

export const isStateZeroPending = (answer) => {
	return (
		answer === PX_QUESTIONS_ACCOUNT_TYPE.PERSONAL_BROKERAGE_ACCOUNT ||
		answer === PX_QUESTIONS_ACCOUNT_TYPE.ROBO_PERSONAL_BROKERAGE_ACCOUNT
	);
};

export default LAUNCH_INFO;
