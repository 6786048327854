import { sendMixpanel } from 'stash/events/utils';

export function dataSharingReadMore() {
	sendMixpanel('BankLink', {
		ScreenName: 'BankLinkOption',
		Action: 'TapOnDataSharingTooltip',
	});
}

export function trackSettingsMenuDataSharingClick() {
	sendMixpanel('UserProfile', {
		ScreenName: 'UserProfile',
		Action: 'MenuOption',
		ButtonName: 'DataSharing',
	});
}

export function trackDataSharingOptInView() {
	sendMixpanel('DataSharingSetting', {
		ScreenName: 'DataSharingOptIn',
	});
}

export function trackDataSharingOptOutView() {
	sendMixpanel('DataSharingSetting', {
		ScreenName: 'DataSharingOptOut',
	});
}

export function trackDataSharingOptOutClick() {
	sendMixpanel('DataSharingSetting', {
		ScreenName: 'DataSharingOptOut',
		Action: 'OptOut',
	});
}

export function trackDataSharingOptOutConfirmClick() {
	sendMixpanel('DataSharingSetting', {
		ScreenName: 'DataSharingOptOut',
		Action: 'ConfirmOptOut',
	});
}

export function trackDataSharingOptInClick() {
	sendMixpanel('DataSharingSetting', {
		ScreenName: 'DataSharingOptIn',
		Action: 'OptIn',
	});
}

export function trackDataSharingOptInRelinkClick() {
	sendMixpanel('DataSharingSetting', {
		ScreenName: 'DataSharingOptIn',
		Action: 'Relink',
	});
}

export function trackDataSharingErrorClick() {
	sendMixpanel('DataSharingSetting', {
		ScreenName: 'DataSharingOptOut',
		Action: 'Error',
	});
}

export function trackCrossSellScreen() {
	sendMixpanel('BankLink', {
		ScreenName: 'CrossSell',
	});
}

export function makeInvestmentCTA() {
	sendMixpanel('BankLink', {
		ScreenName: 'CrossSell',
		Action: 'MakeInvestment',
	});
}

export function addCashCTA() {
	sendMixpanel('BankLink', {
		ScreenName: 'CrossSell',
		Action: 'AddCash',
	});
}

export function reauthErrorScreenView() {
	sendMixpanel('BankLink', {
		ScreenName: 'ConfirmAutoTransactionError',
	});
}

export function reauthErrorScreenCTA() {
	sendMixpanel('BankLink', {
		ScreenName: 'ConfirmAutoTransactionError',
		Action: 'ConfirmAutoTransactionErrorCTA',
	});
}

export function trackBankLinkUserRestrictedError(linkType, ctaClicked = false) {
	const event = {
		ScreenName: 'BankLinkUserRestrictedError',
		LinkType: linkType,
	};

	if (ctaClicked) {
		event.Action = 'BankLinkUserRestrictedErrorCTA';
	}

	sendMixpanel('BankLink', event);
}

export function trackLinkBankMicros(
	accountType = null,
	ctaClicked = false,
	tooltipClicked = false
) {
	const event = {
		ScreenName: 'MicroDepositBankDetails',
	};

	if (ctaClicked) {
		event.Action = 'SubmitBankDetails';
	}

	if (tooltipClicked) {
		event.Action = 'RoutingAndAccountingNumberTooltip';
	}

	if (!!accountType) {
		// Capitalize the first letter of the account type
		event.AccountType =
			accountType.charAt(0).toUpperCase() + accountType.slice(1).toLowerCase();
	}

	sendMixpanel('BankLink', event);
}
