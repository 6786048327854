import { useFetch } from '@stashinvest/use-fetch';
import { getInstrumentationProperties } from './properties';
import { setStoredAttributions } from './storedAttributions';

const useSendAnalytics = () => {
	const { fetch: instrumentationAddWebProperties } = useFetch({
		path: '/apis/customers/v1/analytics/web_properties',
		method: useFetch.POST,
	});

	const { fetch: instrumentationDeleteWebProperties } = useFetch({
		method: useFetch.DELETE,
	});

	const { fetch: instrumentationDeleteMobileProperties } = useFetch({
		method: useFetch.DELETE,
	});

	const sendAnalyticData = async (userUuid) => {
		const addPropertiesBody = {
			userId: userUuid,
			properties: getInstrumentationProperties(),
		};

		instrumentationDeleteMobileProperties({
			path: `/apis/customers/v1/analytics/mobile_properties?userId=${userUuid}`,
		});

		await instrumentationDeleteWebProperties({
			path: `/apis/customers/v1/analytics/web_properties?userId=${userUuid}`,
		});

		instrumentationAddWebProperties({ body: addPropertiesBody });
		setStoredAttributions(addPropertiesBody.properties);
	};

	return { sendAnalyticData };
};

export default useSendAnalytics;
