import { CLOSED_AASM_STATES, RISK_LEVELS } from 'stash/constants/mappings';
import {
	CUSTODIAN,
	PERSONAL_BROKERAGE,
	ROBO_PERSONAL_BROKERAGE,
	ROTH_IRA,
	TRADITIONAL_IRA,
} from 'stash/constants/accountTypes';
import { addMonths, addWeeks, getDateString } from 'stash/utils/time';

export const isEmpty = (obj) => {
	return !obj || !Object.keys(obj).length;
};

export const getAutostashNextExecution = (frequency) => {
	switch (frequency) {
		case 'MONTHLY':
			return getDateString(addMonths(new Date(), 1));
		case 'BI-WEEKLY':
			return getDateString(addWeeks(new Date(), 2));
		case 'WEEKLY':
		default:
			return getDateString(addWeeks(new Date(), 1));
	}
};

// Returns the maximum transaction amount for an account with limits (retire)
export const transactionMax = (currentContributions, year) => {
	if (currentContributions && currentContributions.length) {
		const currentYear =
			currentContributions.find((c) => c.year === parseInt(year, 10)) ||
			currentContributions[0];

		return (
			currentYear.contribution_limit -
			currentYear.raw_pending_value -
			currentYear.raw_completed_value
		);
	}
};

// Returns true if an IRA account can contribute to more than one year
export const canContributeToMultipleYears = (contributions = []) => {
	let availableContributionYears = 0;

	contributions.forEach((c) => {
		const remainingContribution =
			c.contribution_limit - c.raw_pending_value - c.raw_completed_value;

		remainingContribution > 0 && availableContributionYears++;
	});

	return availableContributionYears > 1;
};

export const isRetireAccount = (accountType) => {
	return [TRADITIONAL_IRA, ROTH_IRA].includes(accountType);
};

export const hasClosedAllAccounts = (accounts) => {
	return !!accounts.length && accounts.every((a) => CLOSED_AASM_STATES[a.state]);
};

export const hasAtLeastOneCompletedAccount = (accounts) => {
	return accounts.some((a) => !!a.e_signature_agreed_at);
};

export const filterRiskLevelCards = (cards, userRiskLevel) => {
	const cardsArray = Array.isArray(cards) ? cards : Object.values(cards);
	if (userRiskLevel === RISK_LEVELS.secret) return cardsArray;

	return cardsArray.filter((card) => card.risk_level <= RISK_LEVELS.aggressive);
};

export const getOrderedAccounts = (accounts = []) => {
	// normalize accounts into an array
	const accountArray = Array.isArray(accounts) ? accounts : Object.values(accounts);

	return accountArray.sort((a, b) => {
		// always return personal brokerage first
		if (a.type === PERSONAL_BROKERAGE) return -1;
		if (b.type === PERSONAL_BROKERAGE) return 1;

		// return smart portfolio directly after personal brokerage
		if (a.type === ROBO_PERSONAL_BROKERAGE) return -1;
		if (b.type === ROBO_PERSONAL_BROKERAGE) return 1;

		// custodian accounts are always last
		if (a.type === CUSTODIAN) return 1;
		if (b.type === CUSTODIAN) return -1;

		// sort all IRA accounts in between the others
		return 0;
	});
};

export const parseJwt = (token) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

export const isCancelAccountPending = (action) => {
	return action === 'cancel';
};

export const diffArray = (array1, array2) => {
	return array1.filter((x) => !array2.includes(x));
};
