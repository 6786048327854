import { fetchRequestWithTypes } from './index.js'; // GETs tax documents for a given account.

// GETs tax documents for a given account.

export const GET_PX_TAX_DOCUMENTS_REQUEST = 'GET_PX_TAX_DOCUMENTS_REQUEST';
export const GET_PX_TAX_DOCUMENTS_SUCCESS = 'GET_PX_TAX_DOCUMENTS_SUCCESS';
export const GET_PX_TAX_DOCUMENTS_ERRORED = 'GET_PX_TAX_DOCUMENTS_ERRORED';

export const getPXTaxDocuments = (accountId, filter) => {
	const queryString = filter ? `?filter=${filter}` : '';
	return fetchRequestWithTypes({
		types: [
			GET_PX_TAX_DOCUMENTS_REQUEST,
			GET_PX_TAX_DOCUMENTS_SUCCESS,
			GET_PX_TAX_DOCUMENTS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/investing-accounts/v1/accounts/${accountId}/documents/taxes/by/users/:uuid${queryString}`,
		},
	});
};

// GETs trade confirmations for a given account.

export const GET_TRADE_CONFIRMATIONS_REQUEST = 'GET_TRADE_CONFIRMATIONS_REQUEST';
export const GET_TRADE_CONFIRMATIONS_SUCCESS = 'GET_TRADE_CONFIRMATIONS_SUCCESS';
export const GET_TRADE_CONFIRMATIONS_ERRORED = 'GET_TRADE_CONFIRMATIONS_ERRORED';

export const getTradeConfirmations = (accountId) => {
	return fetchRequestWithTypes({
		types: [
			GET_TRADE_CONFIRMATIONS_REQUEST,
			GET_TRADE_CONFIRMATIONS_SUCCESS,
			GET_TRADE_CONFIRMATIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/clearing/trade_confirms`,
		},
	});
};

// GETs statements for a given account.

export const GET_STATEMENTS_REQUEST = 'GET_STATEMENTS_REQUEST';
export const GET_STATEMENTS_SUCCESS = 'GET_STATEMENTS_SUCCESS';
export const GET_STATEMENTS_ERRORED = 'GET_STATEMENTS_ERRORED';

export const getStatements = (accountId) => {
	return fetchRequestWithTypes({
		types: [GET_STATEMENTS_REQUEST, GET_STATEMENTS_SUCCESS, GET_STATEMENTS_ERRORED],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/clearing/statements`,
		},
	});
};
